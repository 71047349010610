import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DatatypeInfo } from '../../interfaces/datatype-info';
import { DTypeBuilder } from '../../interfaces/dtype-builder';
import { DatatypeService } from '../../services/datatype.service';

@Component({
  selector: 'app-datatype11',
  templateUrl: './datatype11.component.html',
  styleUrls: ['./datatype11.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Datatype11Component),
      multi: true
    }
  ]
})
export class Datatype11Component implements OnInit, ControlValueAccessor {
  datatypeInfo !: DatatypeInfo;
  color        !: string;
  isDisabled   !: boolean;

  @Input() PAGE_INFO    : any;          // Informacion de la Pagina
  @Input() REGIST_DATA !: DTypeBuilder; // Informacion de Configuracion del Tipo de Dato
  @Input() controlForm!: FormControl;
  
  constructor(private datatypeSerice: DatatypeService) { }

  ngOnInit(): void {
    this.datatypeInfo = this.datatypeSerice.buildDatatypeValues(this.REGIST_DATA);
  }

  /************************* NG VALUE ACCESOR *************************/
  onChange = (_: any) => { };
  onTouch  = () => { }

  writeValue(value: any): void {
    if (value) {
      this.color = value || '';
    } else {
      this.color = '';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  pickerChange(value: string) {
    this.color = value;
    this.onTouch();
    this.onChange(this.color);

    // console.log("control form", this.controlForm)
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  /**********************************************************************/

}
