
<ng-container *ngIf="PAGE_INFO.page_type =='F' && PAGE_INFO.fields_type == 'HO' && datatypeInfo">
    <div class="field grid">
        <label for="icon" class="col-12 md:col-2 mb-2  md:mb-0">{{datatypeInfo.regist_title}}</label>
        <div class="col-12 md:col-10">
            <p-multiSelect [options]="datatypeInfo.regist_opcselect"
                           [(ngModel)]="selected" 
                           [defaultLabel]="datatypeInfo.regist_plholder" 
                           optionLabel="no_compag" 
                           (onChange)="setSelect($event.value)">
            </p-multiSelect>
            <ng-container *ngIf="controlForm.errors && controlForm.touched">
                <small *ngIf="controlForm.errors.required" class="p-error">El campo {{datatypeInfo.regist_title | lowercase}} es requerido</small>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="PAGE_INFO.page_type =='F' && PAGE_INFO.fields_type == 'VE'">
    <label for="icon">{{datatypeInfo.regist_title }}</label>
    <div class="mt-1">
        <p-multiSelect [options]="datatypeInfo.regist_opcselect"
                       [(ngModel)]="selected" 
                       [defaultLabel]="datatypeInfo.regist_plholder" 
                       optionLabel="no_compag" 
                       (onChange)="setSelect($event.value)">
        </p-multiSelect>
    </div>
    <ng-container *ngIf="controlForm.errors && controlForm.touched">
        <small *ngIf="controlForm.errors.required" class="p-error">El campo {{datatypeInfo.regist_title | lowercase}} es requerido</small>
    </ng-container>
</ng-container>

<ng-container *ngIf="datatypeInfo && PAGE_INFO.page_type =='T'">
    <p-multiSelect [options]="datatypeInfo.regist_opcselect"
                   [(ngModel)]="selected" 
                   [defaultLabel]="datatypeInfo.regist_plholder" 
                   optionLabel="no_compag" 
                   (onChange)="setSelect($event.value)">
    </p-multiSelect>
</ng-container>

<!-- [(ngModel)]="selected" -->
<!-- dataKey="REGIST_FORM_INFO.regist_value" -->

