import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogPageService } from '../../services/dialog-page.service';
import { MessagePageService } from '../../services/message-page.service';
import { PageService } from '../../services/page.service';

@Component({
  selector: 'app-chart01-page',
  templateUrl: './chart01-page.component.html',
  styleUrls: ['./chart01-page.component.css'],
  providers: [MessageService]
})
export class Chart01PageComponent implements OnInit {
  
  @Input() PAGE_INFO: any;       // Informacion Recibida de MasterPage
  
  chartType: any;
  basicData: any;
  basicOptions: any;

  data: any;
  
  constructor(
    public  pageService         : PageService,
    private messagePageService  : MessagePageService,
    public  messageService      : MessageService,
    public  dialogPageService   : DialogPageService
  ) { }

  ngOnInit(): void {
    this.getPaginaData();
  }

  getPaginaData() {
    // Consultar al Servicio la Informacion de la Pagina a Renderizar
    this.pageService.getPagina(this.PAGE_INFO.id_page).subscribe((result: any) => {
      // Mensaje Consola
      console.log("====> Cargango Pagina", this.PAGE_INFO.id_page);

      // Datos de Chart
      this.chartType = result.data_page[0].dash_card.chart_type;
      this.basicData = result.data_page[0].dash_card.data;
      this.basicOptions = result.data_page[0].dash_card.options;

      // Validar si Existen Alertas a Mostrar
      if (result.msg_alert) {
        this.messagePageService.showAlert(result.msg_alert.msg_title, result.msg_alert.msg_body, result.msg_alert.msg_type);
      };

    }, err => {
      if (err.error.valid === false) {
        // this.openSnackBar("Mensaje de Error", err.error.error_stack, 'danger');
        console.log("error");
        this.showAlert("Mensaje de Error", err.error.error_stack, 'error');
      };
    });
  };

  showAlert(title: any, body: any, type: any) {
    this.messageService.add({ severity: type, summary: title, detail: body });
  }
}
