import { Component, Input, OnInit } from '@angular/core';
import { MessagePageService } from '../../services/message-page.service';
import { PageService } from '../../services/page.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { DialogPageService } from '../../services/dialog-page.service';

@Component({
  selector: 'app-card01-page',
  templateUrl: './card01-page.component.html',
  styleUrls: ['./card01-page.component.css'],
  providers: [MessageService]
})
export class Card01PageComponent implements OnInit {

  @Input() PAGE_INFO: any;       // Informacion Recibida de MasterPage
  cardConfig !: object;
  buttonLoading: boolean = false;
  styleWidth         !: string;          // Tamaño maximo de la pagina

  constructor(
    public pageService         : PageService,
    private messagePageService : MessagePageService,
    public messageService      : MessageService,
    public dialogPageService   : DialogPageService
  ) { }

  ngOnInit(): void {
    this.getPaginaData();
  }

  getPaginaData() {
    // Consultar al Servicio la Informacion de la Pagina a Renderizar
    this.pageService.getPagina(this.PAGE_INFO.id_page).subscribe((result: any) => {
      // Mensaje Consola
      console.log("====> Cargango Pagina", this.PAGE_INFO.id_page);
      // console.log("====> RESULTADO", result.data_page[0].dash_card);

      // Guardar Datos de la Pagina
      this.cardConfig = result.data_page[0].dash_card;
      this.styleWidth = !result.max_width ? '100%' : result.max_width + 'px';

      // Validar si Existen Alertas a Mostrar
      if (result.msg_alert) {
        this.messagePageService.showAlert(result.msg_alert.msg_title, result.msg_alert.msg_body, result.msg_alert.msg_type);
      };

    }, err => {
      if (err.error.valid === false) {
        // this.openSnackBar("Mensaje de Error", err.error.error_stack, 'danger');
        console.log("error");
        this.showAlert("Mensaje de Error", err.error.error_stack, 'error');
      };
    });
  };

  showAlert(title: any, body: any, type: any) {
    // console.log("Entro a mensaje serviceeeeeeeeeeeeeee")
    this.messageService.add({ severity: type, summary: title, detail: body });
  }

  ejecutarBoton(btn_type: string, btn_id: any, btn_conten: number): void {
    this.buttonLoading = true; // Cambiado el icono del boton a cargando

    console.log("====> Procesando Progag:", this.PAGE_INFO.id_page);

    this.pageService.execButton(1, this.PAGE_INFO.id_page, {}).subscribe((result: any) => {
      this.buttonLoading = false;
    });
  }
}