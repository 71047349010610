import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PageService } from 'src/app/pages/services/page.service';
import { DatatypeInfo } from '../../interfaces/datatype-info';
import { DTypeBuilder } from '../../interfaces/dtype-builder';
import { DatatypeService } from '../../services/datatype.service';

@Component({
  selector: 'app-datatype06',
  templateUrl: './datatype06.component.html',
  styleUrls: ['./datatype06.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Datatype06Component),
      multi: true
    }
  ]
})
export class Datatype06Component implements OnInit, ControlValueAccessor, OnChanges {
  
  @Input() PAGE_INFO    : any;          // Informacion de la Pagina
  @Input() REGIST_DATA !: DTypeBuilder; // Informacion de Configuracion del Tipo de Dato
  @Input() controlForm !: FormControl;  // Formulario Reactivo 

  datatypeInfo   !: DatatypeInfo;
  select_options !: Compag[];
  selected       !: Compag;
  filterSelect    : boolean = false;
  registNumber   !: number;
  labelSize       : string = '2';
  fieldSize       : string = '10';

  constructor(private datatypeSerice: DatatypeService, public pageService: PageService) { }

  ngOnInit(): void {
    // Generar Datos para el Registro
    this.generarDatosRegistro(this.REGIST_DATA)

    // Actualizar Registro - Subscripcion al Post
    if (this.datatypeInfo.regist_actReg == true) {
      this.pageService.paginaPos$.subscribe( (res: any) => {
        if (res.id_pagina == this.PAGE_INFO.id_page && res.nu_regist?.includes(this.registNumber)) {
          // Resetear a Nulo el valor
          this.setSelect(null);

          // Actualizar Datos del Registro
          this.generarDatosRegistro({regist_data:res.data[0], regist_name: this.REGIST_DATA.regist_name, regist_title: this.REGIST_DATA.regist_title})
        }
      })
    }
  }

  generarDatosRegistro(data: DTypeBuilder){
    this.datatypeInfo = this.datatypeSerice.buildDatatypeValues(data);

    if (this.PAGE_INFO.fields_label_size[this.datatypeInfo.regist_name]){
      this.labelSize = this.PAGE_INFO.fields_label_size[this.datatypeInfo.regist_name];
    }

    if (this.PAGE_INFO.fields_size[this.datatypeInfo.regist_name]){
      this.fieldSize = this.PAGE_INFO.fields_size[this.datatypeInfo.regist_name];
    }

    this.datatypeInfo.regist_plholder = this.datatypeInfo.regist_plholder == '' ? 'Seleccione' : this.datatypeInfo.regist_plholder;

    if (this.datatypeInfo.regist_filter === true) this.filterSelect = this.datatypeInfo.regist_filter;

    this.registNumber = Number(this.datatypeInfo.regist_name.substring(7));

    this.selected = this.datatypeInfo.regist_opcselect?.find(value => value.co_compag === this.datatypeInfo.regist_value)!;
    console.log("this.datatypeInfo.regist_value", this.datatypeInfo.regist_value)
  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log("changes", changes);
    let regist_data_changes: any;
    
    for (let propName in changes) {
      if (propName == 'REGIST_DATA'){
        regist_data_changes = changes[propName];
      }
    }

    if ( regist_data_changes.firstChange == false && JSON.stringify(regist_data_changes.currentValue) !== JSON.stringify(regist_data_changes.previousValue) ) {
      this.datatypeInfo = this.datatypeSerice.buildDatatypeValues(regist_data_changes.currentValue);

      // Arreglando el PlaceHolder
      this.datatypeInfo.regist_plholder = this.datatypeInfo.regist_plholder == '' ? 'Seleccione' : this.datatypeInfo.regist_plholder;

      this.selected = this.datatypeInfo.regist_opcselect?.find(value => value.co_compag === this.datatypeInfo.regist_value)!;
    }
  }
  
  setSelect(value: Compag | null) {
    this.onTouch();

    if (!value) {
      this.onChange(null);
    } else {
      this.onChange(value.co_compag);
    }
    
    if (this.datatypeInfo.regist_propag == true){
      this.pageService.procesPagePost(this.PAGE_INFO.id_page, this.registNumber, value?.co_compag)
    }
  }

  /*********** NG_VALUE_ACCESSOR ***********/
  isDisabled!: boolean;
  onChange = (_: any) => { }
  onTouch = () => { }

  writeValue(value: any): void {
    if (value) {
      // this.selected.co_compag = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  /******************************************/
}

/****************************************************/
/****************** INTERFACES **********************/
/****************************************************/
interface Compag {
  co_compag: string | null,
  no_compag: string | null
};
/****************************************************/
/****************************************************/