import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { DialogPageService } from '../../services/dialog-page.service';
// import { PageService } from '../../services/page.service';
import { PageService } from '../../services/page.service';

@Component({
  selector: 'app-master-page',
  templateUrl: './master-page.component.html',
  styleUrls: ['./master-page.component.css'],
  providers:[DynamicDialogRef, DynamicDialogConfig]
})
export class MasterPageComponent implements OnInit, OnDestroy {
  
  // Configuracion del Contenedor
  public CONTEN_CONFIG!: any;
  public containerID!: number;
  public newContainerConfig: any;
  public gridList: any;
  public pagesList: any;
  public containerConfig: any;
  public containerGridList: any;

  private paramSubscribe!: any;
  dataModal: any;

  constructor(
    public config: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    public pageService: PageService,
    public dialogPageService: DialogPageService, 
    private paramRoute: ActivatedRoute
  ) { }
  
  ngOnDestroy(): void {
    this.paramSubscribe.unsubscribe();
  }

  ngOnInit(): void {
    this.paramSubscribe = this.paramRoute.queryParams.subscribe( params => {     
      if (this.dialogPageService.contenedorDialog){
        console.log("====> CARGANDO CONTENEDOR DIALOG:", this.dialogPageService.contenedorDialog);
        this.pageService.getConten(this.dialogPageService.contenedorDialog).subscribe( res_service => {
          this.CONTEN_CONFIG = res_service;
        });

        this.pageService.getContenConfig(this.dialogPageService.contenedorDialog).subscribe(res=>{
          this.newContainerConfig = res;

          this.containerConfig = this.newContainerConfig;
          this.containerGridList = this.newContainerConfig.gridList;
          this.buildConfig(res);
        });
      } else {
        console.log("====> CARGANDO CONTENEDOR NORMAL:", params.nu_conten);
        this.pageService.getConten(params.nu_conten).subscribe( res_service => {
          this.CONTEN_CONFIG = res_service;

          this.pageService.addContenName(this.CONTEN_CONFIG.conten_name);
        });

        this.pageService.getContenConfig(params.nu_conten).subscribe(res=>{
          this.newContainerConfig = res;

          this.containerConfig = this.newContainerConfig;
          this.containerGridList = this.newContainerConfig.gridList;
          this.buildConfig(res);
        });

      }
    });
  }

  buildConfig(config: any){
    const containerConfig = config.container;
    this.containerID = containerConfig.id;
    const templateColumns = containerConfig.columns.map((rs: any) => {
      return `minmax(150px, ${rs})`;
    })

    const containerGridClass = `.grid-container_${containerConfig.id} {
      margin: 0px 10px;
      display: grid;
      grid-template-columns: ${templateColumns.join(' ')};
      grid-template-rows: ${containerConfig.rows.join(' ')};
      grid-gap: ${containerConfig.gapValue};
      justify-content: ${containerConfig.justifyContentValue};
    }`;
    
    const styleElement = document.createElement('style');
    styleElement.innerHTML = containerGridClass;
    document.head.appendChild(styleElement);

    containerConfig.gridList.forEach((ele:any) => {
      const containerGridClass = `.grid_${ele.containerId}_${ele.numberOfGrid} {
          grid-column: ${ele.gridColumn};
          grid-row: ${ele.gridRow};
          display: flex;
          flex-direction: ${ele.FlexDirection ?? 'row' }; 
          justify-content: ${ele.FlexJustifyContent};
          align-items: ${ele.FlexAlignItems};
          gap: ${ele.FlexGap};
          /*background-color: beige;*/
          /*border: rgb(202, 202, 206) 1px dashed;*/
          /*padding: 5px;*/
      }`;

      const styleElement = document.createElement('style');
      styleElement.innerHTML = containerGridClass;
      document.head.appendChild(styleElement);
    });

    this.gridList = containerConfig.gridList;

    this.pagesList = containerConfig.pages.map((page:any) => {
      return { id_page: page.idPage, pageType: page.pageType, numberOfGrid: page.numberOfGrid, pageWidth: page.pageWidth }
    });
    console.log("pagesList", this.pagesList)
  };


  // getGridPages(grid_position: any): object[] {
  //   let arrObj: object[] = [];

  //   arrObj.push(this.CONTEN_CONFIG.conten_pages.find((item: any) => item.grid_id === grid_position));
    
  //   return arrObj;
  // }
}
