<!-- <ng-container *ngIf="CONTEN_CONFIG && ![36,3,1,4,16,9,8,15,6,7,56,39,38,37,57,40,50,17,41,10,11,44,54,35,14,13,42,43,20,24,25,23,22,21, 19,18,28,29,58,49,48,2,0,53,52,46,47,55,12,45].includes(CONTEN_CONFIG.conten_id)"> -->
    <!-- Renderiza La Fila  -->
    <!-- <div *ngFor="let row of CONTEN_CONFIG.conten_grid" class="p-grid p-jc-center p-mb-0 p-mt-0 p-mx-4"> -->
        <!-- Renderiza La Columna  -->
        <!-- <div *ngFor ="let column of row | keyvalue" [class]="'p-col-12 ' + 'p-md-' + column.value + ' p-py-1 p-my-1'"> -->
            <!-- Renderiza La Pagina  -->
            <!-- <ng-container *ngFor="let pageInfo of CONTEN_CONFIG.conten_pages">
                <ng-container *ngIf="pageInfo.grid_id == column.key"> -->

                    <!-- PAGINA TIPO FORMULARIO -->
                    <!-- <app-form-page  [PAGE_INFO]="pageInfo" *ngIf="pageInfo.page_type == 'F'"></app-form-page> -->
                    <!-- PAGINA TIPO TABLA -->
                    <!-- <app-table-page [PAGE_INFO]="pageInfo" *ngIf="pageInfo.page_type == 'T'"></app-table-page> -->
                    <!-- PAGINA TIPO DASHBOARD-CARD - 01 -->
                    <!-- <app-card01-page [PAGE_INFO]="pageInfo" *ngIf="pageInfo.page_type == 'C01'"></app-card01-page> -->
                    <!-- PAGINA TIPO CHART - 01 -->
                    <!-- <app-chart01-page [PAGE_INFO]="pageInfo" *ngIf="pageInfo.page_type == 'CH01'"></app-chart01-page> -->

                    <!-- SEPARADOR CUANDO HAY MAS 1 PAGINA POR GRILLA -->
                    <!-- <ng-container *ngFor="let grid of CONTEN_CONFIG.content_grid_spaces">
                        <div *ngIf="grid == column.key" class="p-my-4"></div>
                    </ng-container>
                    
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-container> -->

<!-- <ng-container *ngIf="gridList && [36,3,1,4,16,9,8,15,6,7,56,39,38,37,57,40,50,17,41,10,11,44,54,35,14,13,42,43,20,24,25,23,22,21,19,18,28,29,58,49,48,2,0,53,52,46,47,55,12,45].includes(CONTEN_CONFIG.conten_id)"> -->
    <div [class]="'grid-container_'+containerID">
       <div *ngFor="let grid of gridList" [class]="'grid_'+ grid.containerId + '_' + grid.numberOfGrid">
        <ng-container *ngFor="let page of pagesList" >
            <!-- PAGINA TIPO FORMULARIO -->
            <app-form-page class="form-page shadow-1" [PAGE_INFO]="page" *ngIf="page.pageType == 'F' && page.numberOfGrid == grid.numberOfGrid" [ngStyle]="{'width': page.pageWidth}"></app-form-page>
            <!-- PAGINA TIPO TABLA -->
            <app-table-page class="table-page shadow-1" [PAGE_INFO]="page" *ngIf="page.pageType == 'T' && page.numberOfGrid == grid.numberOfGrid" [ngStyle]="{'width': page.pageWidth}"></app-table-page>
            <!-- PAGINA TIPO DASHBOARD-CARD - 01 -->
            <app-card01-page class="card-page" [PAGE_INFO]="page" *ngIf="page.pageType == 'C01' && page.numberOfGrid == grid.numberOfGrid" [ngStyle]="{'width': page.pageWidth}"></app-card01-page>
            <!-- PAGINA TIPO CHART - 01 -->
            <app-chart01-page class="chart-page" [PAGE_INFO]="page" *ngIf="page.pageType == 'CH01' && page.numberOfGrid == grid.numberOfGrid" [ngStyle]="{'width': page.pageWidth}"></app-chart01-page>
          </ng-container>
       </div>
    </div>
<!-- </ng-container> -->