
<ng-container *ngIf="PAGE_INFO.page_type =='F' && PAGE_INFO.fields_type == 'HO' && datatypeInfo">
    <div class="field grid">
        <label for="icon" [class]="'col-12 md:col-' + labelSize + ' mb-2 md:mb-0'">{{datatypeInfo.regist_title}}</label>
        <div [class]="'col-12 md:col-' + fieldSize">
            <p-dropdown class="p-inputtext-sm"
                        [ngClass]="{'ng-invalid ng-dirty': controlForm.errors && controlForm.touched}"
                        [placeholder]="datatypeInfo.regist_plholder"
                        optionLabel="no_compag"
                        [options]="datatypeInfo.regist_opcselect"
                        [name]="selected?.no_compag"
                        [(ngModel)]="selected"               
                        (onChange)="setSelect($event.value)"
                        [filter]="filterSelect"
                        [baseZIndex]="10000"
                        [showClear]="true">
            </p-dropdown>
            <ng-container *ngIf="controlForm.errors && controlForm.touched">
                <small *ngIf="controlForm.errors.required" class="p-error">El campo {{datatypeInfo.regist_title | lowercase}} es requerido</small>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="PAGE_INFO.page_type =='F' && PAGE_INFO.fields_type == 'VE'">
    <label for="icon">{{datatypeInfo.regist_title }}</label>
    <div class="p-mt-1">
        <p-dropdown class="p-inputtext-sm"
                    [ngClass]="{'ng-invalid ng-dirty': controlForm.errors && controlForm.touched}"
                    [placeholder]="datatypeInfo.regist_plholder"
                    optionLabel="no_compag"
                    [options]="datatypeInfo.regist_opcselect"
                    [name]="selected?.no_compag"
                    [(ngModel)]="selected"               
                    (onChange)="setSelect($event.value)"
                    [filter]="filterSelect"
                    [baseZIndex]="10000"
                    [showClear]="true">
        </p-dropdown>
    </div>
    <ng-container *ngIf="controlForm.errors && controlForm.touched">
        <small *ngIf="controlForm.errors.required" class="p-error">El campo {{datatypeInfo.regist_title | lowercase}} es requerido</small>
    </ng-container>
</ng-container>

<ng-container *ngIf="datatypeInfo && PAGE_INFO.page_type =='T'">
    <p-dropdown class="p-inputtext-sm"
                [placeholder]="datatypeInfo.regist_plholder"
                optionLabel="no_compag"
                [options]="datatypeInfo.regist_opcselect"
                [name]="selected?.no_compag"
                [(ngModel)]="selected"               
                (onChange)="setSelect($event.value)"
                [filter]="filterSelect"
                [showClear]="true">
    </p-dropdown>
</ng-container>

<!-- [(ngModel)]="selected" -->
<!-- dataKey="REGIST_FORM_INFO.regist_value" -->