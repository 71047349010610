import { Component, Input, OnInit } from '@angular/core';
import {MenuItem} from 'primeng/api';
import { PageService } from 'src/app/pages/services/page.service';
import { HomeService } from '../../services/home.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  nombreConten!: string;
  breadcums!: string[];

  constructor(private pageService: PageService, private homeService: HomeService) { }

  ngOnInit(): void {

    this.pageService.contenedorNombre$.subscribe(res =>{
      this.nombreConten = res;
    });
    
    this.homeService.eventBreadcums.subscribe(res =>{
      this.breadcums = res;
    });
  }

}