

<ng-container *ngIf="PAGE_INFO.page_type =='F' && PAGE_INFO.fields_type == 'HO'">
    <div class="field grid" [ngClass]="{'hide': datatypeInfo.isHide}">
        <label class="col-12" for="inputRegist">{{datatypeInfo.regist_title}}</label>
        <div class="col-12 md:col-12">
            <p-editor [(ngModel)]="value" 
                      [ngClass]="{'ng-invalid ng-dirty': controlForm.errors && controlForm.touched}"
                      (ngModelChange)="onInput($event)" 
                      [style.height.px]="heightEditor">
            </p-editor>
            <ng-container *ngIf="controlForm.errors && controlForm.touched">
                <small *ngIf="controlForm.errors.required" class="p-error">El campo {{datatypeInfo.regist_title | lowercase}} es requerido</small>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="PAGE_INFO.page_type =='F' && PAGE_INFO.fields_type == 'VE'">
    <label class="col-12" for="inputRegist">{{datatypeInfo.regist_title}}</label>
    <div class="mt-1">
        <p-editor [(ngModel)]="value" 
                  [ngClass]="{'ng-invalid ng-dirty': controlForm.errors && controlForm.touched}"
                  (ngModelChange)="onInput($event)" 
                  [style.height.px]="heightEditor">
        </p-editor>
        <ng-container *ngIf="controlForm.errors && controlForm.touched">
            <small *ngIf="controlForm.errors.required" class="p-error">El campo {{datatypeInfo.regist_title | lowercase}} es requerido</small>
        </ng-container>
    </div>
</ng-container>