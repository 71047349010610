import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Modulos
import { PagesModule } from '../pages/pages.module';

// Librerias Adicionales
import { PrimeNGModule } from '../prime-ng/prime-ng.module';
// import { SidebarModule } from 'ng-sidebar';
import { SimplebarAngularModule } from 'simplebar-angular';


// Componentes del Modulo
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { MenuBarComponent } from './components/menu-bar/menu-bar.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { SideNavV2Component } from './components/side-nav-v2/side-nav-v2.component';
import { UserDropdownComponent } from './components/user-dropdown/user-dropdown.component';

// Providers
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService } from 'primeng/api';


@NgModule({
  declarations: [SideNavComponent, MenuBarComponent, HomePageComponent, BreadcrumbComponent, SideNavV2Component, UserDropdownComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    PrimeNGModule,
    PagesModule,
    SimplebarAngularModule,
    // SidebarModule.forRoot()
  ],
  providers: [ConfirmationService, DialogService, MessageService]
})
export class HomeModule { }
