<ng-container *ngIf="PAGE_INFO.page_type =='F' && PAGE_INFO.fields_type == 'HO'">
    <div class="field grid" [ngClass]="{'hide': hideIs}">
        <label class="col-12 md:col-2 mb-2 md:mb-0" for="lastname4">{{datatypeInfo.regist_title}}</label>
        <!-- Icono con Ejecucion de Boton -->
        <div *ngIf="!datatypeInfo.isReadOnly" class="col-12 md:col-10">
            <a href="javascript:void(0)">
                <i [class]="datatypeInfo.regist_icon" [style.color]="datatypeInfo.regist_color" [style.fontSize.px]="sizeIcon" (click)="onClick()"></i>
            </a>  
        </div>
        <!-- Icono Solo Lectura -->
        <div *ngIf="datatypeInfo.isReadOnly" class="col-12 md:col-10">
            <i [class]="datatypeInfo.regist_icon" [style.color]="datatypeInfo.regist_color" [style.fontSize.px]="sizeIcon"></i>        
        </div>
    </div>
</ng-container>

<ng-container *ngIf="PAGE_INFO.page_type =='F' && PAGE_INFO.fields_type == 'VE'">
    <label>{{datatypeInfo.regist_title}}</label>
    <!-- Icono con Ejecucion de Boton -->
    <div *ngIf="!datatypeInfo.isReadOnly" class="mt-1 flex align-items-center" style="height: 2.5rem">
        <a href="javascript:void(0)">
            <i [class]="datatypeInfo.regist_icon" [style.color]="datatypeInfo.regist_color" [style.fontSize.px]="sizeIcon" (click)="onClick()"></i>
        </a>  
    </div>
    <!-- Icono Solo Lectura -->
    <div *ngIf="datatypeInfo.isReadOnly" class="mt-1 flex align-items-center" style="height: 2.5rem">
        <i [class]="datatypeInfo.regist_icon" [style.color]="datatypeInfo.regist_color" [style.fontSize.px]="sizeIcon"></i>        
    </div>
</ng-container>

<ng-container *ngIf="PAGE_INFO.page_type =='T'">
    <!-- Validar Tipo de Icono -->
    <ng-container *ngIf="datatypeInfo.isReadOnly; then iconReadOnly; else iconButton"></ng-container>

    <!-- Icono con Ejecucion de Boton -->
    <ng-template #iconButton>
        <a href="javascript:void(0)">
            <i [class]="datatypeInfo.regist_icon" [style.color]="datatypeInfo.regist_color" [style.fontSize.px]="sizeIcon" (click)="onClick()"></i>
        </a>
    </ng-template>

    <!-- Icono solo de Lectura -->
    <ng-template #iconReadOnly>
        <i [class]="datatypeInfo.regist_icon" [style.color]="datatypeInfo.regist_color" [style.fontSize.px]="sizeIcon"></i>
    </ng-template>
</ng-container>

