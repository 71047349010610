<div *ngIf="!PAGE_CONFIG" [style]="{'max-width': styleWidth, 'margin': '0 auto', 'position': 'relative'}">
    <h3 class="title mb-1"></h3>
    <div class="pt-2">
        <ul class="m-0 p-0" style="list-style-type: none;">
            <li class="mb-3">
                <div class="flex">
                    <div style="flex: 1">
                        <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                        <p-skeleton width="100%"></p-skeleton>
                    </div>
                </div>
            </li>
            <li class="mb-3">
                <div class="flex">
                    <div style="flex: 1">
                        <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                        <p-skeleton width="100%"></p-skeleton>
                    </div>
                </div>
            </li>
            <li class="mb-3">
                <div class="flex">
                    <div style="flex: 1">
                        <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                        <p-skeleton width="100%"></p-skeleton>
                    </div>
                </div>
            </li>
            <li>
                <div class="flex">
                    <div style="flex: 1">
                        <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                        <p-skeleton width="100%"></p-skeleton>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>

<!-- <div *ngIf="PAGE_CONFIG" [style]="{'max-width': styleWidth, 'margin': '0 auto', 'position': 'relative'}" class="p-shadow-1"> -->
<ng-container *ngIf="PAGE_CONFIG">
    <h3 *ngIf="PAGE_CONFIG.show_title" class="title mb-1" [style.background-color]="PAGE_CONFIG.title_color" [style.color]="PAGE_CONFIG.font_titcolor" [style.height.px]="PAGE_CONFIG.title_height"> 
        {{PAGE_CONFIG.page_title}} {{nuPagina}}
        <a *ngIf="PAGE_CONFIG.dev_mode" href="javascript:void(0)" style="margin-left: 1px;">
            <i class="pi pi-chevron-circle-down ml-2" [style.color]="'white'" style=" vertical-align: middle;" (click)="showRegistDev()"></i>
        </a>
    </h3>
    <p-table #dt 
             [value]="tableData" 
             [(selection)]="rowSelected" 
             dataKey="id" 
             [autoLayout]="true"
             [rowHover]="true" 
             [globalFilterFields]="registPage" 
             [rows]="15" 
             [paginator]="PAGE_CONFIG.paginator"  
             [rowsPerPageOptions]="[15,25,50,75,100,200]" 
             [loading]="tableLoading"
             [styleClass]="styleTableSize"
             [sortMode]="sortModeTable"
             
             >

        <ng-template pTemplate="caption" *ngIf="PAGE_CONFIG.search">
            
            <div class="flex align-items-center justify-content-between">
                <div>
                    <ng-container *ngFor="let buttonPage of PAGE_CONFIG.table_header_button">
                        <p-button [label]="buttonPage.btn_name"
                                  [ngClass]="{'button-disabled': buttonLoading}"
                                  [icon]="buttonLoading == true && buttonPage.btn_id == buttonIdLoading && buttonPage.btn_ico_loading == true ? icoLoading : buttonPage.btn_ico_prifaces"
                                  (click)="procesButton('T', buttonPage.btn_id, buttonPage.btn_conten, rowSelected, buttonPage.btn_confirm, buttonPage.btn_txt_confirm)"
                                  [style]="{'color': 'white', 'background': buttonPage.btn_color}"
                                  [pTooltip]="buttonPage.btn_txt_tooltip" 
                                  [tooltipPosition]="buttonPage.btn_pos_tooltip"
                                  class="ml-2">
                        </p-button>
                    </ng-container>
                </div>
                <div>
                    <span class="p-input-icon-left mr-2">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="applyFilterGlobal($event)" placeholder="Buscar..." />
                    </span>
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="header" *ngIf="PAGE_CONFIG.header_table" >
            <tr [ngStyle]="{'height': PAGE_CONFIG.sort_column ? '3rem' : ''}">
                <!-- Checkbox -->
                <th *ngIf="PAGE_CONFIG.checkbox_table" style="width: 2.5em" [style.background]="headerColor">
                    <p-tableHeaderCheckbox (change)="selectAll();"></p-tableHeaderCheckbox>
                </th>

                <!-- Columnas -->
                <ng-container *ngIf="PAGE_CONFIG.sort_column; then sortableHeader; else normalHeader"></ng-container>
                <ng-template #sortableHeader>
                    <th [style.background]="headerColor" [style.color]="fontHeaderColor" *ngFor="let header of PAGE_CONFIG.regist_title | keyvalue: returnZero" pSortableColumn="{{header.key}}" >{{header.value}}<p-sortIcon field="{{header.key}}"></p-sortIcon></th>
                </ng-template>
                <ng-template #normalHeader>
                    <th [style.background]="headerColor" [style.color]="fontHeaderColor" *ngFor="let header of PAGE_CONFIG.regist_title | keyvalue: returnZero">{{header.value}}</th>
                </ng-template>

                <!-- Botones -->
                <th [style.background]="headerColor" [style.color]="fontHeaderColor" *ngIf="PAGE_CONFIG.regist_button"></th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-tableData let-rowIndex="rowIndex">
            <tr >
                <!-- Checkbox -->
                <td *ngIf="PAGE_CONFIG.checkbox_table">
                    <p-checkbox [value]="tableData" [(ngModel)]="rowSelected"></p-checkbox>
                </td>

                <!-- Columnas -->
                <td pEditableColumn *ngFor="let regist of PAGE_CONFIG.regist_title | keyvalue: returnZero" [style.white-space]="'nowrap'">

                    <ng-container *ngIf="tableData[regist.key + '_est'] == 'E'; then editableRow; else readOnlyRow"></ng-container>

                    <ng-template #readOnlyRow>
                        <div>
                            <ng-container [ngSwitch]="PAGE_CONFIG.regist_type[regist.key]">
                                <app-datatype02 *ngSwitchCase="2"  [PAGE_INFO]="PAGE_INFO" [REGIST_DATA]="{'regist_data': tableData, 'regist_name': regist.key}" [(ngModel)]="tableData[regist.key]"></app-datatype02>
                                <app-datatype03 *ngSwitchCase="3"  [PAGE_INFO]="PAGE_INFO" [REGIST_DATA]="{'regist_data': tableData, 'regist_name': regist.key}" [(ngModel)]="tableData[regist.key]"></app-datatype03>
                                <app-datatype12 *ngSwitchCase="12" [PAGE_INFO]="PAGE_INFO" [REGIST_DATA]="{'regist_data': tableData, 'regist_name': regist.key}"></app-datatype12>
                                <app-datatype13 *ngSwitchCase="13" [PAGE_INFO]="PAGE_INFO" [REGIST_DATA]="{'regist_data': tableData, 'regist_name': regist.key}" [(ngModel)]="tableData[regist.key]"></app-datatype13>
                                <div *ngSwitchDefault>{{tableData[regist.key]}}</div>
                            </ng-container>
                        </div>
                    </ng-template>

                    <ng-template #editableRow>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <ng-container [ngSwitch]="PAGE_CONFIG.regist_type[regist.key]">
                                    <app-datatype01 *ngSwitchCase="1" [PAGE_INFO]="PAGE_INFO" [REGIST_DATA]="{'regist_data': tableData, 'regist_name': regist.key}" [(ngModel)]="tableData[regist.key]"></app-datatype01>
                                    <app-datatype06 *ngSwitchCase="6" [PAGE_INFO]="PAGE_INFO" [REGIST_DATA]="{'regist_data': tableData, 'regist_name': regist.key}" [(ngModel)]="tableData[regist.key]"></app-datatype06>
                                    <div *ngSwitchDefault>{{tableData[regist.key]}}</div>
                                </ng-container>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <ng-container [ngSwitch]="PAGE_CONFIG.regist_type[regist.key]">
                                    <app-datatype12 *ngSwitchCase="12" [PAGE_INFO]="PAGE_INFO" [REGIST_DATA]="{'regist_data': tableData, 'regist_name': regist.key}"></app-datatype12>
                                    <div *ngSwitchDefault>{{tableData[regist.key]}}</div>
                                </ng-container>
                            </ng-template>
                        </p-cellEditor>
                    </ng-template>

                </td>

                <!-- Botones -->
                <td *ngIf="PAGE_CONFIG.regist_button" style="padding-top: 0px; padding-bottom: 0px;" [style.white-space]="'nowrap'">
                    <button *ngFor="let buttonRow of PAGE_CONFIG.regist_button" 
                            id="buttonRegist"
                            (click)="procesButton('E', buttonRow.btn_id, buttonRow.btn_conten, tableData, buttonRow.btn_confirm, buttonRow.btn_txt_confirm)" 
                            [icon]="buttonRow.btn_ico_prifaces"
                            [ngStyle]="{'color': buttonRow.btn_color}"
                            [pTooltip]="buttonRow.btn_txt_tooltip" 
                            [tooltipPosition]="buttonRow.btn_pos_tooltip"
                            class="p-button-rounded p-button-danger p-button-text" 
                            type="button" 
                            pButton
                            pRipple>
                    </button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-tableData>
            <tr>
                <td [attr.colspan]="registPage.length + 1">
                    No se ha encontrado información para mostrar
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div *ngIf="PAGE_CONFIG.page_button" class="flex justify-content-center py-2">
        <ng-container  *ngFor="let buttonPage of PAGE_CONFIG.page_button">
            <p-button styleClass="p-button-sm"
                      [ngClass]="{'button-disabled': buttonLoading}"
                      [label]="buttonPage.btn_name"
                      [icon]="buttonLoading == true && buttonPage.btn_id == buttonIdLoading && buttonPage.btn_ico_loading == true ? icoLoading : buttonPage.btn_ico_prifaces"
                      (click)="procesButton('G', buttonPage.btn_id, buttonPage.btn_conten, rowSelected, buttonPage.btn_confirm, buttonPage.btn_txt_confirm)"
                      [style]="{'color': 'white', 'background': buttonPage.btn_color}"
                      [pTooltip]="buttonPage.btn_txt_tooltip" 
                      [tooltipPosition]="buttonPage.btn_pos_tooltip"
                      class="ml-2">
            </p-button>
        </ng-container>
    </div>
    <!-- (click)="ejecutarBoton('G', buttonPage.btn_id, buttonPage.btn_conten, rowSelected)" -->
    <!-- CONFIRM DIALOG -->
    <p-confirmDialog [style]="{width: '420px'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" [dismissableMask]="true"></p-confirmDialog>   
</ng-container>

<div *ngIf="showRegDev" 
     class="px-4 py-3 mt-2 shadow-1 dev-message" 
     [style.width.%]="95">
     <pre *ngIf="PAGE_CONFIG.checkbox_table"><b style="color: blue;">ROWS SELECTED: {{rowSelected | json}}</b></pre>
     <pre><b style="color: #2B629E;">TABLE DATA: {{tableData | json}}</b></pre>
</div>