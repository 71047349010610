import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './home/components/home-page/home-page.component';
import { MainSystemComponent } from './sub-sistemas/main-system/main-system.component';
import { AuthGuard } from './home/guards/auth.guard';
import { ROUTES } from './home/home-routing.module';
import { LoginComponent } from './login/login.component';
import { Card01PageComponent } from './pages/components/card01-page/card01-page.component';
import { Chart01PageComponent } from './pages/components/chart01-page/chart01-page.component';

const routes: Routes = [
  {path:'home', component: HomePageComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard],  children: ROUTES},
  {path:'login', component: LoginComponent},
  {path:'card01', component: Card01PageComponent},
  {path:'chart01', component: Chart01PageComponent},
  {path:'subsistemas', component: MainSystemComponent},
  {path:'**', component: LoginComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
