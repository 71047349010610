import { Component, OnInit } from '@angular/core';
import { DialogPageService } from '../../pages/services/dialog-page.service';
import { RoutesService } from '../../home/services/routes.service';

@Component({
  selector: 'app-main-system',
  templateUrl: './main-system.component.html',
  styleUrls: ['./main-system.component.css']
})
export class MainSystemComponent implements OnInit {

  public menuSystem !: object[];
  private userCode !: string | null | undefined;

  constructor(public dialogPageService: DialogPageService, private routeService: RoutesService) { }

  ngOnInit(): void {
    this.userCode = localStorage.getItem('codigo_usuario');
    let localMenuStorage: string = localStorage.getItem('systemMenu') ?? '[]';
    
    this.menuSystem = JSON.parse(localMenuStorage);
  }

 getSideMenu(systemSelect: any){
    console.log("systemSelect", systemSelect)

    this.routeService.getRoutes(this.userCode, systemSelect.idSystem).subscribe((res:any) => {
      this.routeService.buildMenuGroup(res);
      console.log("todo el meni", res)
    });

    this.dialogPageService.closeDialog();
  }

}
