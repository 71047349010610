import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MenuItem, MessageService, ConfirmationService, ConfirmEventType } from 'primeng/api';
import { MessagePageService } from 'src/app/pages/services/message-page.service';
import { DialogPageService } from '../../../pages/services/dialog-page.service';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
  providers: [MessageService]
})
export class HomePageComponent implements OnInit, OnDestroy {
  items: MenuItem[] = [];
  showMessage: any;
  options = { autoHide: true, scrollbarMinSize: 100, forceVisible: true};
  @ViewChild('sidenav') sidenav: any;
  isMobile: boolean= false;

  constructor(
      public  dialogPageService: DialogPageService,
      private messageService: MessageService, 
      private messagePageService: MessagePageService
  ) { }

  tipoSlideMenu: string = 'push';
  closeOnClickOutside: boolean = false;
  showBackdrop: boolean = false;
  nombreModulo!: string;
  opened: boolean = true;
 
  public _toggleSidebar() {
    this.opened = !this.opened;
  }
  
  ngOnDestroy(): void {
    this.showMessage.unsubscribe();
  }

  ngOnInit(): void {
    if (window.innerWidth <= 768){
      this.tipoSlideMenu = 'over';
      this.showBackdrop = true;
      this.closeOnClickOutside = true;
      this.isMobile = true;
    } else {
      this.tipoSlideMenu = 'push';
      this.showBackdrop = false;
      this.closeOnClickOutside = false;
      this.isMobile = false;
    };

    this.showMessage = this.messagePageService.message.subscribe((res: any) =>{
      //console.log("subscripcion al res:", res);

      this.showMessages(res.title,res.body,res.type);
    })

    const screenWidth = window.innerWidth;

    if (screenWidth <= 768){
      this.tipoSlideMenu = 'over';
      this.showBackdrop = true;
      this.closeOnClickOutside = true;
    } else {
      this.tipoSlideMenu = 'push';
      this.showBackdrop = false;
      this.closeOnClickOutside = false;
    }
    
    // setTimeout(() => {
    //   this.dialogPageService.buildMainMenu(null, 60);
    // }, 2000);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth <= 768){
      this.tipoSlideMenu = 'over';
      this.showBackdrop = true;
      this.closeOnClickOutside = true;
      this.isMobile = true;
    } else {
      this.tipoSlideMenu = 'push';
      this.showBackdrop = false;
      this.closeOnClickOutside = false;
      this.isMobile = false;
    }
  }

  @HostListener('document:click', ['$event'])
  public hideDropdown(event: any) {
    if (this.isMobile && !this.sidenav.nativeElement.contains(event.target)) {
      if (event.target?.id === 'buttonMenu' || event.target.offsetParent?.id === 'buttonMenu' ){
        return;
      }
      
      if (this.opened) {
        this.opened = !this.opened;
      }
    } 
  }

  showMessages(title: string, body: string, type: string) {
    this.messageService.add({severity: type, summary: title, detail: body, life:6000});
  }
}
