import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessagePageService {
  public message = new Subject<object>();

  constructor() { }

  showAlert(title: string, body: string, type: string) {
    this.message.next({title, body, type});
  }
}
