<div *ngIf="cardConfig" class="grid grid-nogutter shadow-3 p-4" style="background: var(--surface-e)">

    <div *ngIf="cardConfig.icon" class="col-2 md:col-1 flex justify-content-center">
        <i [class]="cardConfig.icon" class="mt-1" style="color: var(--text-color)" [style.fontSize.px]="cardConfig.icon_size_px"></i>
    </div>

    <div [ngClass]="cardConfig.icon ? 'col-10 md:col-11': 'col-12'" class="p-1">
        <div class="grid grid-nogutter">
            <div class="col-12 p-style-font">
                <div class="flex justify-content-between">
                    <div>
                        <div class="title-01">{{cardConfig.head_title}}</div>
                        <div class="subtitle">{{cardConfig.head_subtitle}}</div>
                    </div>
                    <div>
                        <button *ngIf="cardConfig.head_button_label" type="button"
                                (click)="ejecutarBoton(null, null, null)"
                                [style.background]="cardConfig.head_button_background"
                                [style.color]="cardConfig.head_button_color_font"
                                [label]="cardConfig.head_button_label" 
                                [icon]="cardConfig.head_button_icon" 
                                class="p-button-sm"
                                [ngClass]="{'button-disabled': buttonLoading}"
                                pRipple
                                pButton>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12 py-2">
                <hr>
            </div>
            <div class="col-12">
                <div class="grid grid-nogutter">
                    <ng-container *ngFor="let item of cardConfig.content_body">
                        <div [class]="'col-' + item.col_size">
                            <div class="mb-1 subtitle" >{{item.label}}</div>
                            <div class="counter">{{item.value}}</div>
                        </div>  
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<p-toast></p-toast>