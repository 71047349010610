<ng-container *ngIf="PAGE_INFO.page_type =='F' && PAGE_INFO.fields_type == 'HO' && datatypeInfo">
    <div class="field grid">
        <label for="timeonly" class="col-12 md:col-2 mb-2  md:mb-0">{{datatypeInfo.regist_title}}</label>
        <div class="col-12 md:col-10">
            <p-calendar class="p-inputtext-sm"
                        [ngClass]="{'ng-invalid ng-dirty': controlForm.errors && controlForm.touched}"
                        [showIcon]="true"
                        icon="pi pi-clock"
                        readonlyInput="true"
                        [(ngModel)]="date"
                        (onSelect)="onSelect($event)"
                        (onClearClick)="clearCalendarValues()"
                        (onClickOutside)="onTouch()"
                        hourFormat="24"
                        [timeOnly]="true" 
                        inputId="timeonly"
                        showButtonBar="true">
            </p-calendar>
            <ng-container *ngIf="controlForm.errors && controlForm.touched">
                <small *ngIf="controlForm.errors.required" class="p-error">El campo {{datatypeInfo.regist_title | lowercase}} es requerido</small>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="PAGE_INFO.page_type =='F' && PAGE_INFO.fields_type == 'VE'">
    <label for="timeonly">{{datatypeInfo.regist_title}}</label>
    <div class="mt-1">
        <p-calendar class="p-inputtext-sm"
                    [ngClass]="{'ng-invalid ng-dirty': controlForm.errors && controlForm.touched}"
                    [showIcon]="true"
                    icon="pi pi-clock"
                    readonlyInput="true"
                    [(ngModel)]="date"
                    (onSelect)="onSelect($event)"
                    (onClearClick)="clearCalendarValues()"
                    (onClickOutside)="onTouch()"
                    hourFormat="24"
                    [timeOnly]="true" 
                    inputId="timeonly"
                    showButtonBar="true">
        </p-calendar>
        <ng-container *ngIf="controlForm.errors && controlForm.touched">
            <small *ngIf="controlForm.errors.required" class="p-error">El campo {{datatypeInfo.regist_title | lowercase}} es requerido</small>
        </ng-container>
    </div>
</ng-container>