import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DatatypeInfo } from '../../interfaces/datatype-info';
import { DTypeBuilder } from '../../interfaces/dtype-builder';
import { DatatypeService } from '../../services/datatype.service';

@Component({
  selector: 'app-datatype13',
  templateUrl: './datatype13.component.html',
  styleUrls: ['./datatype13.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Datatype13Component),
      multi: true
    }
  ]
})
export class Datatype13Component implements OnInit, ControlValueAccessor {
  datatypeInfo !: DatatypeInfo;
  isDisabled   !: boolean;

  @Input() PAGE_INFO    : any;          // Informacion de la Pagina
  @Input() REGIST_DATA !: DTypeBuilder; // Informacion de Configuracion del Tipo de Dato
  @Input() controlForm !: FormControl;

  checked: boolean = false;
  checkDisabled: boolean = false;

  constructor(private datatypeSerice: DatatypeService) { }

  ngOnInit(): void {
    this.datatypeInfo = this.datatypeSerice.buildDatatypeValues(this.REGIST_DATA);
    this.checkDisabled = this.datatypeInfo.regist_disabled || false;
    console.log("this.datatypeInfo", this.datatypeInfo)
  }

  /************************* NG VALUE ACCESOR *************************/
  onChange = (_: any) => { };
  onTouch  = () => { }

  writeValue(value: boolean): void {
    if (value) {
      this.checked = value || false;
    } else {
      this.checked = false;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  pickerChange(value: boolean) {
    this.checked = value;
    this.onTouch();
    this.onChange(this.checked);

    console.log("value", value)
    // console.log("control form", this.controlForm)
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  /**********************************************************************/
}
