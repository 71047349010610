import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Librerias Adicionales
import { PrimeNGModule } from '../prime-ng/prime-ng.module';
// import { TooltipModule } from 'ng2-tooltip-directive';

// Componentes
import { Datatype01Component } from './components/datatype01/datatype01.component';
import { Datatype06Component } from './components/datatype06/datatype06.component';
import { Datatype08Component } from './components/datatype08/datatype08.component';
import { Datatype09Component } from './components/datatype09/datatype09.component';
import { Datatype10Component } from './components/datatype10/datatype10.component';
import { Datatype02Component } from './components/datatype02/datatype02.component';
import { Datatype03Component } from './components/datatype03/datatype03.component';
import { Datatype04Component } from './components/datatype04/datatype04.component';
import { Datatype05Component } from './components/datatype05/datatype05.component';
import { Datatype11Component } from './components/datatype11/datatype11.component';
import { Datatype12Component } from './components/datatype12/datatype12.component';
import { Datatype07Component } from './components/datatype07/datatype07.component';
import { Datatype13Component } from './components/datatype13/datatype13.component';
import { Datatype14Component } from './components/datatype14/datatype14.component';


@NgModule({
  declarations: [
    Datatype01Component,
    Datatype02Component,
    Datatype03Component,
    Datatype04Component,
    Datatype05Component,
    Datatype06Component,
    Datatype07Component,
    Datatype08Component,
    Datatype09Component,
    Datatype10Component,
    Datatype11Component,
    Datatype12Component,
    Datatype13Component,
    Datatype14Component
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    PrimeNGModule,
    // TooltipModule
  ],
  exports:[
    Datatype01Component,
    Datatype02Component,
    Datatype03Component,
    Datatype04Component,
    Datatype05Component,
    Datatype06Component,
    Datatype07Component,
    Datatype08Component,
    Datatype09Component,
    Datatype10Component,
    Datatype11Component,
    Datatype12Component,
    Datatype13Component,
    Datatype14Component
  ]
})
export class DatatypesModule { }
