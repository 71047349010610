import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MessageService, ConfirmationService, ConfirmEventType } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { DialogPageService } from '../../services/dialog-page.service';
import { MessagePageService } from '../../services/message-page.service';
import { PageService } from '../../services/page.service';

@Component({
	selector: 'app-table-page',
	templateUrl: './table-page.component.html',
	styleUrls: ['./table-page.component.css'],
	providers: [ConfirmationService, DialogService]
})
export class TablePageComponent implements OnInit {

	@ViewChild('dt') dt	: any;					// Filtro de Datos Tabla
	@Input() PAGE_INFO	: any;    				// Informacion Recibida de MasterPage
	PAGE_CONFIG			: any;           		// Configuracion de la Pagina

	registPage		   !: string[];      		// Registros de la Pagina
	tableData			: any;					// Data para la Tabla
	rowSelected			: any[] = [];			// Data para la Tabla
	showRegDev  		: boolean = false; 		// Modo de Desarrollo
	nuPagina			: string = '';			// Numero de Pagina
	styleWidth         !: string;               // Tamaño maximo de la pagina

	sortModeTable		: string = '';			// Ordenar Datos Columna
	styleTableSize		: string = '';			// Estilo - Tamaño de las Filas
	headerColor			: string = '';			// Estilo - Color Fondo de la Cabecera Tabla
	fontHeaderColor		: string = '#000000';   // Estilo - Color de la Letra de la Cabecera Tabla

	tableLoading		: boolean = false;		// Estilo - Fondo Durante Carga Tabla
	buttonIdLoading		: number | null = null; // Numero de Boton en Carga
	buttonLoading		: boolean = false;		// Indicador de Carga del Boton
	icoLoading			: string = 'pi pi-spin pi-spinner'; // Estilo - Icono de Carga Boton

	constructor(
		public  dialogPageService	: DialogPageService,
		public  messagePageService	: MessagePageService,
		public  dialogService		: DialogService,
		private pageService			: PageService,
		private messageService		: MessageService,
		private confirmationService	: ConfirmationService
	) { }

	ngOnInit(): void {
		this.getPaginaData(); // Cargar Datos Pagina

		// Recargar Datos Pagina
		this.pageService.parametros$.subscribe( (res: any) =>{
			if (res.pages_to_refresh && res.pages_to_refresh.includes(this.PAGE_INFO.id_page)) {
				this.getPaginaData();
			}
		})
	}

	selectAll() {
		this.rowSelected = this.tableData;
	};

	/************** CALL TO SERVICES **************/
	getPaginaData() {
		// Cargando Tabla
		this.tableLoading = true;

		// Consultar al Servicio la Informacion de la Pagina a Renderizar
		this.pageService.getPagina(this.PAGE_INFO.id_page).subscribe((result: any) => {
			// Mensaje Consola
			console.log("====> Page Loaded:", this.PAGE_INFO.id_page);

			// Guardar Datos de la Pagina
			this.PAGE_CONFIG     = result;
			this.nuPagina        = this.PAGE_CONFIG.dev_mode ? ' - ' + this.PAGE_CONFIG.id_page : '';
			this.styleTableSize  = this.PAGE_CONFIG.style_size == 'normal' ? '' : 'p-datatable-sm';
			this.sortModeTable   = this.PAGE_CONFIG.sort_column ? 'multiple' : '';
			this.headerColor     = String(this.PAGE_CONFIG.header_color).length > 0 ? this.PAGE_CONFIG.header_color : '';
			this.fontHeaderColor = String(this.PAGE_CONFIG.font_header_color).length > 0 ? this.PAGE_CONFIG.font_header_color : this.fontHeaderColor;
			this.styleWidth      = !this.PAGE_CONFIG.max_width ? '100%' : this.PAGE_CONFIG.max_width + 'px';
			
			// Obtener los nombres de los registros (Keys del Objeto)
			this.registPage = Object.keys(this.PAGE_CONFIG.regist_title);

			// Recorrer los regist para ver cual se debe retirar y no hacer render
			this.registPage.forEach(regist => {
				// No Renderizar Columna - Modo Oculto
				try {
					if (this.PAGE_CONFIG.data_page[0][regist+"_est"] === 'O'){
						delete this.PAGE_CONFIG.regist_title[regist];
					};	
				} catch (error) {
					// console.log("====> Pagina", this.PAGE_INFO.id_page, "No se encontraron registros a mostrar")
				}
			});

			// Validar si Existen Alertas a Mostrar
			if (result.msg_alert){
				// this.showAlert(result.msg_alert.msg_title, result.msg_alert.msg_body, result.msg_alert.msg_type);
				this.messagePageService.showAlert(result.msg_alert.msg_title, result.msg_alert.msg_body, result.msg_alert.msg_type);
			};

			// Data de la Tabla
			this.tableData 	  = this.PAGE_CONFIG.data_page;
			this.tableLoading = false;
		}, err => {
			if (err.error.valid === false) {
			  console.warn("error");
			  this.showAlert(`Mensaje de Error - Pagina ${ err.error.page_id }`, err.error.error_stack, 'error');
			};

			this.tableLoading = false;
		});
	};
	
	public procesButton(btn_type: string, btn_id: any, btn_conten: number, row: any, btn_confirm: boolean, btn_txt_confirm: string){
		if ( btn_confirm == true ) {
			this.confirmationService.confirm({
				message: btn_txt_confirm,
				header: 'Confirmación',
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					this.ejecutarBoton(btn_type, btn_id, row);
				},
				reject: (type: any) => {
					switch(type) {
						case ConfirmEventType.REJECT:
						//this.messageService.add({severity:'error', summary:'Rejected', detail:'You have rejected'});
						break;
						case ConfirmEventType.CANCEL:
						//this.messageService.add({severity:'warn', summary:'Cancelled', detail:'You have cancelled'});
						break;
					}
				}
			});
		} else{
			this.ejecutarBoton(btn_type, btn_id, row);
		}
	}

	public ejecutarBoton(buttonType: string, buttonNumber: number, row: any): void {

		let registData = buttonType === 'E' ? row
					   : this.PAGE_CONFIG.checkbox_table ? this.rowSelected
					   : buttonType === 'T' ? this.tableData
					   : buttonType === 'G' ? this.tableData 
					   : null;

		let allButtons = buttonType === 'E' ? this.PAGE_CONFIG.regist_button 
					   : buttonType === 'T' ? this.PAGE_CONFIG.table_header_button 
					   : buttonType === 'G' ? this.PAGE_CONFIG.page_button 
					   : null;
		
		let selectButtonInfo = allButtons.find((item: { btn_id: any; }) => item.btn_id === buttonNumber);

		if ( buttonType === 'G' || buttonType === 'T' ) {
			this.buttonLoading = true;
			this.buttonIdLoading = buttonNumber;
		};

		console.log("====> Procesando Progag:", this.PAGE_CONFIG.id_page) // Mensaje Consola

		this.pageService.execButton(buttonNumber, this.PAGE_CONFIG.id_page, registData).subscribe((res:any) => {
			this.buttonLoading   = false; // Resetear Icono Loading del Boton
			this.buttonIdLoading = null;  // Resetear Numero de Boton Cargando
			this.rowSelected     = []; 	  // Reseteando las Filas Seleccionadas
		});
	}

	showAlert(title: any, body: any, type: any) {
		this.messageService.add({severity:type, summary: title, detail: body});
	};

	applyFilterGlobal($event: Event) {
		this.dt.filterGlobal(($event.target as HTMLInputElement).value, 'contains');
	}

	log(anydate: any) {
		console.log("Log===>", anydate);
	}

	showRegistDev(){
		this.showRegDev = !this.showRegDev;
	}
	
	returnZero() {
		return 0;
	}
}
