<div *ngIf="!PAGE_CONFIG" [style]="{'max-width': styleWidth, 'margin': '0 auto', 'position': 'relative'}">
    <h3 class="title mb-2"></h3>
    <div class="mt-3">
        <div class="flex mb-3">
            <p-skeleton shape="circle" size="4rem" styleClass="p-mr-2"></p-skeleton>
            <div style="width: 100%;">
                <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                <p-skeleton width="100%" ></p-skeleton>
            </div>
        </div>
        <p-skeleton height="1.5rem" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="100%" height="6rem"></p-skeleton>
    </div>
</div>

<!-- <div *ngIf="PAGE_CONFIG" [ngStyle]="{'max-width': styleWidth}" class="form-page p-shadow-1 card"> -->
<ng-container *ngIf="PAGE_CONFIG">
    <!-- Titulo Pagina  -->
    <h3 *ngIf="PAGE_CONFIG.show_title" class="title mb-4 "  [style.background-color]="PAGE_CONFIG.title_color" [style.color]="PAGE_CONFIG.font_titcolor" [style.height.px]="PAGE_CONFIG.title_height">
        {{PAGE_CONFIG.page_title}} {{nuPagina}}
        <a *ngIf="PAGE_CONFIG.dev_mode" href="javascript:void(0)" style="margin-left: 1px;">
            <i class="pi pi-chevron-circle-down p-ml-2" [style.color]="'white'" style=" vertical-align: middle;" (click)="showRegistDev()"></i>
        </a>
    </h3>

    <!-- Separador - Espaciador  -->
    <div *ngIf="!PAGE_CONFIG.show_title" class="mb-3">
        &nbsp;
    </div>

    <!-- Formulario  -->
    <div [formGroup]="formGroup" [ngClass]="PAGE_CONFIG.fields_type == 'VE' ? 'formgrid grid p-fluid px-5': 'p-fluid px-5'" [class]="styleInputs" >
        <ng-container *ngFor="let registName of registPage">
            <div [ngSwitch]="PAGE_CONFIG.regist_type[registName]" 
                 [ngClass]="PAGE_CONFIG.fields_type == 'VE' ? 'field col-12 md:col-' + PAGE_CONFIG.fields_size[registName] : ''">

                <app-datatype01 *ngSwitchCase="1"  [PAGE_INFO]="PAGE_CONFIG"  [controlForm]="formGroup.get(registName)" [REGIST_DATA]="{'regist_data': PAGE_DATA, 'regist_name': registName, 'regist_title': PAGE_TITLES[registName]}" [formControlName]="registName"></app-datatype01>
                <app-datatype02 *ngSwitchCase="2"  [PAGE_INFO]="PAGE_CONFIG"                                            [REGIST_DATA]="{'regist_data': PAGE_DATA, 'regist_name': registName, 'regist_title': PAGE_TITLES[registName]}" [formControlName]="registName"></app-datatype02>
                <app-datatype03 *ngSwitchCase="3"  [PAGE_INFO]="PAGE_CONFIG"                                            [REGIST_DATA]="{'regist_data': PAGE_DATA, 'regist_name': registName, 'regist_title': PAGE_TITLES[registName]}" [formControlName]="registName"></app-datatype03>
                <app-datatype04 *ngSwitchCase="4"  [PAGE_INFO]="PAGE_CONFIG"  [controlForm]="formGroup.get(registName)" [REGIST_DATA]="{'regist_data': PAGE_DATA, 'regist_name': registName, 'regist_title': PAGE_TITLES[registName]}" [formControlName]="registName"></app-datatype04>
                <app-datatype05 *ngSwitchCase="5"  [PAGE_INFO]="PAGE_CONFIG"  [controlForm]="formGroup.get(registName)" [REGIST_DATA]="{'regist_data': PAGE_DATA, 'regist_name': registName, 'regist_title': PAGE_TITLES[registName]}" [formControlName]="registName"></app-datatype05>
                <app-datatype06 *ngSwitchCase="6"  [PAGE_INFO]="PAGE_CONFIG"  [controlForm]="formGroup.get(registName)" [REGIST_DATA]="{'regist_data': PAGE_DATA, 'regist_name': registName, 'regist_title': PAGE_TITLES[registName]}" [formControlName]="registName"></app-datatype06>
                <app-datatype07 *ngSwitchCase="7"  [PAGE_INFO]="PAGE_CONFIG"  [controlForm]="formGroup.get(registName)" [REGIST_DATA]="{'regist_data': PAGE_DATA, 'regist_name': registName, 'regist_title': PAGE_TITLES[registName]}" [formControlName]="registName"></app-datatype07>
                <app-datatype08 *ngSwitchCase="8"  [PAGE_INFO]="PAGE_CONFIG"  [controlForm]="formGroup.get(registName)" [REGIST_DATA]="{'regist_data': PAGE_DATA, 'regist_name': registName, 'regist_title': PAGE_TITLES[registName]}" [formControlName]="registName"></app-datatype08>
                <app-datatype09 *ngSwitchCase="9"  [PAGE_INFO]="PAGE_CONFIG"  [controlForm]="formGroup.get(registName)" [REGIST_DATA]="{'regist_data': PAGE_DATA, 'regist_name': registName, 'regist_title': PAGE_TITLES[registName]}" [formControlName]="registName"></app-datatype09>
                <app-datatype10 *ngSwitchCase="10" [PAGE_INFO]="PAGE_CONFIG"  [controlForm]="formGroup.get(registName)" [REGIST_DATA]="{'regist_data': PAGE_DATA, 'regist_name': registName, 'regist_title': PAGE_TITLES[registName]}" [formControlName]="registName"></app-datatype10>
                <app-datatype11 *ngSwitchCase="11" [PAGE_INFO]="PAGE_CONFIG"  [controlForm]="formGroup.get(registName)" [REGIST_DATA]="{'regist_data': PAGE_DATA, 'regist_name': registName, 'regist_title': PAGE_TITLES[registName]}" [formControlName]="registName"></app-datatype11>
                <app-datatype14 *ngSwitchCase="14" [PAGE_INFO]="PAGE_CONFIG"  [controlForm]="formGroup.get(registName)" [REGIST_DATA]="{'regist_data': PAGE_DATA, 'regist_name': registName, 'regist_title': PAGE_TITLES[registName]}" [formControlName]="registName"></app-datatype14>
                <div *ngSwitchDefault>Sin tipo dato</div>
            </div>
        </ng-container>
    </div>
    <div class="flex justify-content-center pb-2">
        <ng-container  *ngFor="let buttonPage of PAGE_CONFIG.page_button">
            <p-button [label]="buttonPage.btn_name"
                      [icon]="buttonLoading == true && buttonPage.btn_id == buttonIdLoading && buttonPage.btn_ico_loading == true ? icoLoading : buttonPage.btn_ico_prifaces"
                      (click)="procesButton(buttonPage.btn_id, buttonPage.btn_conten, buttonPage.btn_confirm, buttonPage.btn_txt_confirm, buttonPage.btn_validators)"
                      [style]="{'background':buttonPage.btn_color, 'color':'white'}"
                      [pTooltip]="buttonPage.btn_txt_tooltip" 
                      [tooltipPosition]="buttonPage.btn_pos_tooltip"
                      styleClass="p-button-sm"
                      [ngClass]="{'button-disabled': buttonLoading}"
                      class="ml-2">
            </p-button>
        </ng-container>
    </div>
</ng-container>

<!-- (click)="ejecutarBoton('G', buttonPage.btn_id, buttonPage.btn_conten)" -->
<p-confirmDialog [style]="{width: '420px'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" [dismissableMask]="true"></p-confirmDialog>   

<p-toast></p-toast>

<!-- Modo Developer -->
<div *ngIf="showRegDev" 
     class="px-4 py-3 mt-2 shadow-1 dev-message" 
     [style.width.%]="styleWidth">
    <b>
    <div>Valid: {{ formGroup.valid }}</div>
    <div>Status: {{ formGroup.status }}</div>
    <pre>Data: {{ formGroup.value | json }}</pre>
    </b> 
</div>


