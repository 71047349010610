import { Component, HostListener, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AuthService } from 'src/app/login/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.css']
})
export class UserDropdownComponent implements OnInit {
  tooglePerfil: boolean = false;
  @ViewChild('dropdownUser') dropdownUser: any;
  userName    : string | null = localStorage.getItem('usuarioLogeado');
  initNames  !: string;
  perfilUser !: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.perfilUser = localStorage.getItem('perfil_usuario') ?? '';
    
    let names : string[] | undefined = localStorage.getItem('usuarioLogeado')?.split(' ');

    this.initNames = names?.reduce((acum, current)=>{
      acum = acum + current.charAt(0);
      return acum;
    }, '') ?? '';
  }

  perfilButton() {
    this.tooglePerfil = !this.tooglePerfil;

    if (this.tooglePerfil) {
      this.renderer.addClass(this.document.getElementById('menu_profile'), 'hide__profile')
    } else {
      this.renderer.removeClass(this.document.getElementById('menu_profile'), 'hide__profile')
    };
  }

  @HostListener('document:click', ['$event'])
  public hideDropdown(event: any) {
    if (this.tooglePerfil && !this.dropdownUser.nativeElement.contains(event.target)) {
      this.closeUserButton()
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  closeUserButton() {
    this.tooglePerfil = false;
    this.renderer.removeClass(this.document.getElementById('menu_profile'), 'hide__profile')
  }
}
