import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modulos de Prime NG
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { BadgeModule } from 'primeng/badge';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChartModule } from 'primeng/chart';
import { CardModule } from 'primeng/card';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { RippleModule } from 'primeng/ripple';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SkeletonModule } from 'primeng/skeleton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [],
  imports: [
    AutoCompleteModule,
    AvatarModule,
    CommonModule,
    ButtonModule,
    BadgeModule,
    BreadcrumbModule,
    CheckboxModule,
    ChipModule,
    ChartModule,
    CardModule,
    CalendarModule,
    ConfirmDialogModule,
    ColorPickerModule,
    DialogModule,
    DynamicDialogModule,
    DropdownModule,
    EditorModule,
    FileUploadModule,
    InputTextModule,
    InputTextareaModule,
    MenuModule,
    MessagesModule,
    MessageModule,
    MenubarModule,
    MultiSelectModule,
    OverlayPanelModule,
    PanelMenuModule,
    PanelModule,
    RippleModule,
    SplitButtonModule,
    SlideMenuModule,
    SkeletonModule,
    TableModule,
    ToastModule,
    ToolbarModule,
    TooltipModule
  ],
  exports: [
    AutoCompleteModule,
    AvatarModule,
    ButtonModule,
    BadgeModule,
    BreadcrumbModule,
    CheckboxModule,
    ChipModule,
    ChartModule,
    CardModule,
    CalendarModule,
    ConfirmDialogModule,
    ColorPickerModule,
    DialogModule,
    DynamicDialogModule,
    DropdownModule,
    EditorModule,
    FileUploadModule,
    InputTextModule,
    InputTextareaModule,
    MenuModule,
    MessagesModule,
    MessageModule,
    MenubarModule,
    MultiSelectModule,
    OverlayPanelModule,
    PanelMenuModule,
    PanelModule,
    RippleModule,
    SplitButtonModule,
    SlideMenuModule,
    SkeletonModule,
    TableModule,
    ToastModule,
    ToolbarModule,
    TooltipModule
  ]
})
export class PrimeNGModule { }
