<div #dropdownUser class="perfil__slide">
    <div class="perfil_circle" (click)="perfilButton()">{{initNames}}</div>
    <!-- <button>YO</button> -->
    <!-- <i class="fa-solid fa-chevron-right list__arrow"></i> -->
    <div id="menu_profile" class="menu__profile">
        <h3>
            {{ userName }}<br>
            <span>{{ perfilUser }}</span>
        </h3>

        <ul>
            <li (click)="closeUserButton()">
                <i class="fa-regular fa-user"></i>
                <a routerLink="/home/system" [queryParams]="{nu_conten: '3'}">Perfil</a>
            </li>
            <li>
                <i class="fa-regular fa-pen-to-square"></i>
                <a>Edit Profile</a>
            </li>
            <li>
                <i class="fa-regular fa-envelope"></i>
                <a>Inbox</a>
            </li>
            <li>
                <i class="fa-solid fa-gear"></i>
                <a>Settings</a>
            </li>
            <li>
                <i class="fa-solid fa-circle-question"></i>
                <a>Help</a>
            </li>
            <div class="divider"></div>
            <li class="logout" (click)="logout()">
                <i class="fa-solid fa-power-off"></i>
                <a>Logout</a>
            </li>
        </ul>

    </div>
</div>