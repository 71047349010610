<ng-container *ngIf="PAGE_INFO.page_type =='F'; then tipoFormulario; else tipoTabla"></ng-container>

<ng-template #tipoFormulario>
    <div class="field grid" [ngClass]="{'hide': datatypeInfo.isHide}">
        <label class="col-12 md:col-2 mb-2 md:mb-0" for="inputRegist">{{datatypeInfo.regist_title}}</label>
        <div class="col-12 md:col-10">
            <div class="span" [style.background]="datatypeInfo.regist_color" [style.color]="'white'"> {{ value }}</div>
        </div>
    </div>
</ng-template>

<ng-template #tipoTabla>
    <div class="span" [style.background]="datatypeInfo.regist_color" [style.color]="'white'"> {{ value }}</div>
</ng-template>