// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //urlPages: 'http://localhost:3000',
  //urlFrame: 'http://localhost:3000'
  urlFrame: 'http://165.227.68.255:52564',
  urlRenderManager: 'http://165.227.68.255:52600',
  urlLogin: 'http://165.227.68.255:5151'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
