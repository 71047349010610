<ng-container *ngIf="PAGE_INFO.page_type =='F' && PAGE_INFO.fields_type == 'HO'">
    <div class="field grid" [ngClass]="{'hide': datatypeInfo.isHide}">
        <label class="col-12 md:col-2 mb-2 md:mb-0" for="lastname4">{{datatypeInfo.regist_title}}</label>
        <div class="col-12 md:col-10 flex justify-content-start">
            <p-fileUpload #form 
                        chooseLabel="Adjuntar" 
                        chooseIcon="pi pi-paperclip" 
                        uploadIcon="pi pi-upload" 
                        mode="basic" 
                        name="myfile[]" 
                        customUpload="true" 
                        showCancelButton="true" 
                        (onSelect)="onSelectEvent($event, form)">
            </p-fileUpload>
            <p-button *ngIf="cleanAtach" class="p-pl-2" (click)="clearAttach($event, form)" icon="pi pi-trash" ></p-button>
        </div>
        <ng-container *ngIf="controlForm.errors && controlForm.touched">
            <small *ngIf="controlForm.errors.required" class="p-error">El campo {{datatypeInfo.regist_title | lowercase}} es requerido</small>
        </ng-container>
    </div>
</ng-container>

<ng-container *ngIf="PAGE_INFO.page_type =='F' && PAGE_INFO.fields_type == 'VE'">
    <label for="lastname4">{{datatypeInfo.regist_title}}</label>
    <div class="mt-1 flex justify-content-start">
        <p-fileUpload #form 
                    chooseLabel="Adjuntar" 
                    chooseIcon="pi pi-paperclip" 
                    uploadIcon="pi pi-upload" 
                    mode="basic" 
                    name="myfile[]" 
                    customUpload="true" 
                    showCancelButton="true" 
                    (onSelect)="onSelectEvent($event, form)">
        </p-fileUpload>
        <p-button *ngIf="cleanAtach" class="pl-2" (click)="clearAttach($event, form)" icon="pi pi-trash" ></p-button>
    </div>
    <ng-container *ngIf="controlForm.errors && controlForm.touched">
        <small *ngIf="controlForm.errors.required" class="p-error">El campo {{datatypeInfo.regist_title | lowercase}} es requerido</small>
    </ng-container>
</ng-container>