import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainSystemComponent } from './main-system/main-system.component';



@NgModule({
  declarations: [MainSystemComponent],
  imports: [
    CommonModule
  ]
})
export class SubSistemasModule { }
