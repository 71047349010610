


<ng-container *ngIf="PAGE_INFO.page_type =='F' && PAGE_INFO.fields_type == 'HO'">
    <div class="field grid">
        <label class="col-12 md:col-2 mb-2 md:mb-0" for="lastname4">{{datatypeInfo.regist_title}}</label>
        <div class="col-12 md:col-10">
            <textarea [rows]="rowHeigth"
                      cols="30"                    
                      [placeholder]="placeHolder"
                      [value]="value"
                      [disabled]="readonly"
                      [autoResize]="readonly"
                      [ngClass]="{'ng-invalid ng-dirty': controlForm.errors && controlForm.touched}"
                      (input)="onInput($event.target.value)"
                      (blur)="onTouch()"
                      pInputTextarea>
            </textarea>
            <ng-container *ngIf="controlForm.errors && controlForm.touched">
                <small *ngIf="controlForm.errors.required" class="p-error">El campo {{datatypeInfo.regist_title | lowercase}} es requerido</small>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="PAGE_INFO.page_type =='F' && PAGE_INFO.fields_type == 'VE'">
    <label for="lastname4">{{datatypeInfo.regist_title}}</label>
    <div class="mt-1">
        <textarea [rows]="rowHeigth" 
                    cols="30"                    
                    [placeholder]="placeHolder"
                    [value]="value"
                    [disabled]="readonly"
                    [autoResize]="readonly"
                    [ngClass]="{'ng-invalid ng-dirty': controlForm.errors && controlForm.touched}"
                    (input)="onInput($event.target.value)"
                    (blur)="onTouch()"
                    pInputTextarea>
        </textarea>
        <ng-container *ngIf="controlForm.errors && controlForm.touched">
            <small *ngIf="controlForm.errors.required" class="p-error">El campo {{datatypeInfo.regist_title | lowercase}} es requerido</small>
        </ng-container>
    </div>
</ng-container>