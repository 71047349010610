<!-- <p-menubar [model]="items">
    <ng-template pTemplate="start">
        <img src="assets/universe_svg.svg" height="30" class="p-mr-2">
    </ng-template>
    
    <ng-template pTemplate="end">
        <input type="text" pInputText placeholder="Search">
    </ng-template>
</p-menubar> -->

<p-toolbar styleClass="toolbarS">
    <div class="p-toolbar-group-left">
        <!-- <p-button label="New" icon="pi pi-plus" class="p-mr-2"></p-button>
        <p-button label="Upload" icon="pi pi-upload" styleClass="p-button-success"></p-button>
            
        <i class="p-toolbar-separator pi pi-bars p-mr-2" style="vertical-align: middle"></i>
        
        <p-splitButton label="Save" icon="pi pi-check" [model]="items" styleClass="p-button-warning"></p-splitButton>
        <img src="assets/logo.png" height="32" class="p-mr-3"> -->
        
        <button pButton 
                pRipple
                id="buttonMenu"
                type="button" 
                icon="pi pi-bars" 
                (click)="toogleMenu();" 
                class="p-button-rounded p-button-text p-button-plain mr-2" 
            >
            </button>

        <!-- <div *ngIf="showSystemName" class="p-text-bold p-pb-0" style="color:white">GRUNTH</div> -->
        <!-- <div *ngIf="showSystemName" class="p-text-bold p-pb-0" style="color:#555b6d">
            <a style="text-decoration: none; color: inherit;" [routerLink]="['/home/system']" [queryParams]="{'nu_conten': 1}" routerLinkActive="router-link-active" ></a>
        </div> -->
    </div>
    
    <!-- <div class="p-toolbar-group-right">
        <p-button icon="pi pi-search" class="p-mr-2"></p-button>
        <p-button icon="pi pi-calendar" styleClass="p-button-success p-mr-2"></p-button>
        <p-button icon="pi pi-times" styleClass="p-button-danger"></p-button>
    </div> -->

    <div class="p-toolbar-group-right">
        <a *ngIf="userMessages" href="javascript: void(0)" class="link-reset">
            <i *ngIf="userMessages.pending_msg > 0" pBadge class="pi pi-envelope mr-0" style="font-size: 1.5rem; color: #555b6d;" [value]="userMessages.pending_msg" severity="danger" (click)="op.toggle($event)"></i>
            <i *ngIf="userMessages.pending_msg == 0" pBadge class="pi pi-envelope mr-0" style="font-size: 1.5rem; color: #555b6d;" severity="danger" (click)="op.toggle($event)"></i>
        </a>
        <p-overlayPanel  #op [style]="styleMensajePane">
            <ng-template pTemplate>
                <div class="style-font">
                    <!-- Cabecera -->
                    <div class="flex justify-content-between align-items-center py-3 px-4">
                        <div class="title-01">{{'Notificaciones'}}</div>
                        <a class="link-01" href="javascript: void(0)" (click)="goMessages(); op.hide()">{{'Ver todo'}}</a>
                    </div>
                    <!-- Cuerpo Deslizante -->
                    <div class="py-0">
                        <ngx-simplebar [options]="options">

                            <ng-container *ngIf="userMessages.info_msg">
                                <div id="grilla" *ngFor="let item of userMessages.info_msg" class="mb-1">
                                    <a href="javascript: void(0)" class="link-reset" (click)="goMessages(); op.hide()">
                                        <div class="grid m-0">
                                            <div class="col-2 flex justify-content-center align-items-center">
                                                <div>
                                                    <i [class]="item.ico_class" class="mt-0" [style.color]="item.ico_color" [style.fontSize.px]="'26'"></i>
                                                </div>
                                            </div>
                                            <div class="col-10">
                                                <div>
                                                    <div class="title-body-01">{{item.title_msg}}</div>
                                                    <div class="title-body-02">{{item.subtitle_msg}}</div>
                                                    <div class="title-body-02"><i style="font-size: 10px;" class="far fa-clock mr-1"></i>{{item.date_msg}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="!userMessages.info_msg">
                                <div id="grilla"  class="mb-1">
                                    <a href="javascript: void(0)" class="link-reset" (click)="goMessages(); op.hide()">
                                        <div class="grid m-0">
                                            <div class="col-2 flex justify-content-center align-items-center">
                                                <div>
                                                    <i [class]="'fas fa-times-circle'" class="mt-0" [style.color]="'#f35d52'" [style.fontSize.px]="'26'"></i>
                                                </div>
                                            </div>
                                            <div class="col-10">
                                                <div>
                                                    <div class="title-body-01">{{'No hay mensajes'}}</div>
                                                    <div class="title-body-02">{{'No tiene ningun mensaje de alerta'}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </ng-container>
                            
                        </ngx-simplebar>
                    </div>
                    <!-- Footer -->
                    <div class="flex justify-content-center align-items-center pb-3 pt-3">
                        <a href="javascript: void(0)" class="link-reset" (click)="goMessages(); op.hide()">
                            <div class="title-footer"><i class="fas fa-arrow-circle-right"></i> Ver más...</div>
                        </a>
                    </div>
                </div>
            </ng-template>
        </p-overlayPanel>

        <!-- <i pBadge class="pi pi-bell p-mr-5" style="font-size: 1.5rem; color: white;" value="2" severity="danger"></i> -->
        <!-- <i pBadge class="pi pi-calendar p-mr-5" style="font-size: 1.5rem; color: white" [value]="'10+'" severity="danger"></i> -->
        <!-- <i pBadge class="pi pi-envelope p-mr-2" style="font-size: 1.5rem; color: white" severity="danger" value="2"></i> -->
        

        <!-- <p-avatar label="E" styleClass="p-ml-5"  [style]="{'background-color':'#2196F3', 'color': '#ffffff'}" shape="circle"></p-avatar> -->
        <!-- <p-avatar *ngIf="userSystem !== 'Erick Palomino'"
                  icon="pi pi-user"
                  styleClass="p-mr-1 p-ml-5"
                  [style]="{'background-color':'#2196F3', 'color': '#ffffff'}" 
                  shape="circle">
        </p-avatar>
        <p-avatar *ngIf="userSystem == 'Erick Palomino'"
                  id="avatarIcono"
                  image="assets/avatar-1.png" 
                  styleClass="p-mr-1 p-ml-5" 
                  shape="circle">
        </p-avatar>
        <button pButton type="button" pRipple class="p-button-text p-mr-2 p-px-1" style="color: #555b6d" icon="pi pi-chevron-down" [label]="userSystem" iconPos="right" (click)="menu.toggle($event)"></button>
        <p-slideMenu #menu [model]="items" [popup]="true" [viewportHeight]="150" ></p-slideMenu> -->
        <app-user-dropdown></app-user-dropdown>
    </div>
</p-toolbar>