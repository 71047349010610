import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DatatypeInfo } from '../../interfaces/datatype-info';
import { DTypeBuilder } from '../../interfaces/dtype-builder';
import { DatatypeService } from '../../services/datatype.service';

@Component({
  selector: 'app-datatype14',
  templateUrl: './datatype14.component.html',
  styleUrls: ['./datatype14.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Datatype14Component),
      multi: true
    }
  ]
})
export class Datatype14Component implements OnInit, ControlValueAccessor {
  @Input() PAGE_INFO    : any;          // Informacion de la Pagina
  @Input() REGIST_DATA !: DTypeBuilder; // Informacion de Configuracion del Tipo de Dato
  @Input() controlForm !: FormControl;  // Formulario Reactivo 

  datatypeInfo   !: DatatypeInfo;
  select_options !: Compag[];
  selected       !: Compag;
  
  constructor(private datatypeSerice: DatatypeService) { }

  ngOnInit(): void {
    this.datatypeInfo = this.datatypeSerice.buildDatatypeValues(this.REGIST_DATA);
    
    this.datatypeInfo.regist_plholder = this.datatypeInfo.regist_plholder == '' ? 'Seleccione' : this.datatypeInfo.regist_plholder;
  }

  setSelect(value: Compag) {
    this.onTouch();

    if (!value) {
      this.onChange(null);
    } else {
      this.onChange(value);
    }
  }

  /*********** NG_VALUE_ACCESSOR ***********/
  isDisabled!: boolean;
  onChange = (_: any) => { }
  onTouch = () => { }

  writeValue(value: any): void {
    if (value) {
      // this.selected.co_compag = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  /******************************************/
};

/****************************************************/
/****************** INTERFACES **********************/
/****************************************************/
interface Compag {
  co_compag: string | null,
  no_compag: string | null
};
/****************************************************/
/****************************************************/


  

