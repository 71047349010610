import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DatatypeInfo } from '../../interfaces/datatype-info';
import { DTypeBuilder } from '../../interfaces/dtype-builder';
import { DatatypeService } from '../../services/datatype.service';

@Component({
  selector: 'app-datatype01',
  templateUrl: './datatype01.component.html',
  styleUrls: ['./datatype01.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Datatype01Component),
      multi: true
    }
  ]
})
export class Datatype01Component implements OnInit, ControlValueAccessor {

  @Input() PAGE_INFO    : any;          // Informacion de la Pagina
  @Input() REGIST_DATA !: DTypeBuilder; // Informacion de Configuracion del Tipo de Dato

  datatypeInfo !: DatatypeInfo;
  value        !: string;
  isDisabled   !: boolean;
  labelSize : string = '2';
  fieldSize : string = '10';

  @Input() controlForm!: FormControl;

  constructor(private datatypeSerice: DatatypeService) { }

  ngOnInit(): void {
    this.datatypeInfo = this.datatypeSerice.buildDatatypeValues(this.REGIST_DATA);    
    
    if (this.PAGE_INFO.fields_label_size[this.datatypeInfo.regist_name]){
      this.labelSize = this.PAGE_INFO.fields_label_size[this.datatypeInfo.regist_name];
    }

    if (this.PAGE_INFO.fields_size[this.datatypeInfo.regist_name]){
      this.fieldSize = this.PAGE_INFO.fields_size[this.datatypeInfo.regist_name];
    }
  }

  /************************* NG VALUE ACCESOR *************************/
  onChange = (_: any) => { };
  onTouch  = () => { }

  writeValue(value: any): void {
    if ( typeof value === 'undefined' || value === null ) {
      this.value = '';
    } else {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onInput(value: string) {
    this.value = value;
    this.onTouch();
    this.onChange(this.value);

    // console.log("control form", this.controlForm)
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  /**********************************************************************/
}
