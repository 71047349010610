

<ng-container *ngIf="PAGE_INFO.page_type =='F' && PAGE_INFO.fields_type == 'HO'">
    <div class="field grid" [ngClass]="{'hide': datatypeInfo.isHide}">
        <label [class]="'col-12 md:col-' + labelSize + ' mb-2 md:mb-0'" for="inputRegist">{{datatypeInfo.regist_title}}</label>
        <div [class]="'col-12 md:col-' + fieldSize">
            <input id="inputRegist"
                   type="text"
                   [ngClass]="{'ng-invalid ng-dirty': controlForm.errors && controlForm.touched}"
                   class="p-inputtext-sm"
                   [placeholder]="datatypeInfo.regist_plholder"
                   [value]="value"
                   [disabled]="datatypeInfo.isReadOnly"
                   (input)="onInput($event.target.value)"
                   (blur)="onTouch()"
                   pInputText>
            
            <ng-container *ngIf="controlForm.errors && controlForm.touched">
                <small *ngIf="controlForm.errors.required" class="p-error">El campo {{datatypeInfo.regist_title | lowercase}} es requerido</small>
            </ng-container>
            <!-- class="p-inputtext-sm ng-invalid ng-dirty"  clases para poner en rojo cuando hay error -->
        </div>
    </div>
</ng-container>

<ng-container *ngIf="PAGE_INFO.page_type =='F' && PAGE_INFO.fields_type == 'VE'">
    <label  for="inputRegist">{{datatypeInfo.regist_title}}</label>
    <input  id="inputRegist"
            type="text"
            [ngClass]="{'ng-invalid ng-dirty': controlForm.errors && controlForm.touched}"
            class="p-inputtext-sm mt-1"
            [placeholder]="datatypeInfo.regist_plholder"
            [value]="value"
            [disabled]="datatypeInfo.isReadOnly"
            (input)="onInput($event.target.value)"
            (blur)="onTouch()"
            pInputText>
    <ng-container *ngIf="controlForm.errors && controlForm.touched">
        <small *ngIf="controlForm.errors.required" class="p-error">El campo {{datatypeInfo.regist_title | lowercase}} es requerido</small>
    </ng-container>
</ng-container>

<ng-container *ngIf="PAGE_INFO.page_type =='T'">
    <input  type="text"
            [value]="value"
            (input)="onInput($event.target.value)"
            (blur)="onTouch()"
            pInputText>
</ng-container>

