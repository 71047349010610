import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { HomeService } from '../../services/home.service';
import { RoutesService } from '../../services/routes.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {
  menuGroup: any[] = [];
  userCode !: string | null | undefined;
  options = { autoHide: true, scrollbarMinSize: 100 };

  constructor(
    private routeService: RoutesService,
    private homeService: HomeService
  ) { }

  ngOnInit(): void {
    this.userCode = localStorage.getItem('codigo_usuario');
    // Rutas del Menu Navegacion
    this.routeService.getRoutes(this.userCode, null).subscribe(res => {
      this.buildMenuGroup(res);
    });

  };

  buildMenuGroup(menu_group: any) {
    let menuItems: MenuItem[] = [];

    for (var index in menu_group) {
      if (menu_group[index].route_menus) {
        // Creando la Lista de Links
        menu_group[index].route_menus.forEach((element: { displayName: any; iconName: any; route: any; id_conten: any; children: any; breadcum_items: any; }) => {
          let objMenu: any = {};

          objMenu.label = element.displayName;
          objMenu.icon = element.iconName;

          if (element.id_conten) {
            objMenu.routerLink = ['/home/system'];
            objMenu.queryParams = { 'nu_conten': element.id_conten };
            objMenu.breadcums = element.breadcum_items;
            objMenu.command = (event: any) => {
              this.homeService.setBreadcumsItems(event.item.breadcums)
              //event.originalEvent: Browser event
              //event.item: menuitem metadata
            };
          }

          if (element.children) {
            let objItemArr: any[] = [];

            element.children.forEach((element: { displayName: any; iconName: any; route: any; id_conten: any; breadcum_items: any; }) => {
              let objItems: any = {};

              objItems.label = element.displayName;
              //objItems.icon        = element.iconName; -- Sin icono lvl 2 menu
              objItems.routerLink = ['/home/system'];
              objItems.queryParams = { 'nu_conten': element.id_conten };
              objItems.breadcums = element.breadcum_items;
              objItems.command = (event: any) => {
                this.homeService.setBreadcumsItems(event.item.breadcums)
                //event.originalEvent: Browser event
                //event.item: menuitem metadata
              };

              objItemArr.push(objItems);
            });

            objMenu.items = objItemArr;
          };


          menuItems.push(objMenu)
        });

        this.menuGroup.push({
          name: menu_group[index].route_group,
          items: menuItems
        })

        menuItems = [];
      }
    }
  }
}
