

<ng-container *ngIf="PAGE_INFO.page_type =='F' && PAGE_INFO.fields_type == 'HO'">
    <div class="field grid" [ngClass]="{'hide': datatypeInfo.isHide}">
        <label class="col-12 md:col-2 mb-2 md:mb-0" for="inputRegist">{{datatypeInfo.regist_title}}</label>
        <div #inputRegist class="col-12 md:col-10">
            <p-colorPicker [(ngModel)]="color"  style="width: 100%;"
                           [ngClass]="{'ng-invalid ng-dirty': controlForm.errors && controlForm.touched}"
                           (onChange)="pickerChange(color)">
            </p-colorPicker>
            <ng-container *ngIf="controlForm.errors && controlForm.touched">
                <br>
                <small *ngIf="controlForm.errors.required" class="p-error">El campo {{datatypeInfo.regist_title | lowercase}} es requerido</small>
            </ng-container>
        </div>
        
    </div>
</ng-container>

<ng-container *ngIf="PAGE_INFO.page_type =='F' && PAGE_INFO.fields_type == 'VE'">
    <label for="inputRegist">{{datatypeInfo.regist_title}}</label>
    <div class="mt-1 flex align-items-center" style="height: 2.5rem">
        
            <p-colorPicker [(ngModel)]="color" style="width: 100%;"
                        [ngClass]="{'ng-invalid ng-dirty': controlForm.errors && controlForm.touched}"
                        (onChange)="pickerChange(color)" >
            </p-colorPicker>
        
    </div>
    <ng-container *ngIf="controlForm.errors && controlForm.touched">
        <small *ngIf="controlForm.errors.required" class="p-error">El campo {{datatypeInfo.regist_title | lowercase}} es requerido</small>
    </ng-container>
</ng-container>

<ng-container *ngIf="PAGE_INFO.page_type =='T'">
    <p-colorPicker [(ngModel)]="color"></p-colorPicker>
</ng-container>

