<ng-container *ngIf="PAGE_INFO.page_type =='F' && PAGE_INFO.fields_type == 'HO'">
    <div class="field grid">
        <label for="autocomplete" class="col-12 md:col-2 mb-2 md:mb-0">{{datatypeInfo.regist_title}}</label>
        <div class="col-12 md:col-10">
            <p-autoComplete class="p-inputtext-sm" 
                            [(ngModel)]="selectedData" 
                            [suggestions]="filteredData" 
                            (completeMethod)="filterData($event)" 
                            [ngClass]="{'ng-invalid ng-dirty': controlForm.errors && controlForm.touched}"
                            (onSelect)="onSelect($event)"
                            (onClear)="onClear($event)"
                            field="no_compag" 
                            [minLength]="1">
            </p-autoComplete>
            <ng-container *ngIf="controlForm.errors && controlForm.touched">
                <small *ngIf="controlForm.errors.required" class="p-error">El campo {{datatypeInfo.regist_title | lowercase}} es requerido</small>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="PAGE_INFO.page_type =='F' && PAGE_INFO.fields_type == 'VE'">
    <label for="autocomplete">{{datatypeInfo.regist_title}}</label>
    <div class="mt-1">
        <p-autoComplete class="p-inputtext-sm" 
                        [(ngModel)]="selectedData" 
                        [suggestions]="filteredData" 
                        (completeMethod)="filterData($event)"
                        [ngClass]="{'ng-invalid ng-dirty': controlForm.errors && controlForm.touched}"
                        (onSelect)="onSelect($event)" 
                        field="no_compag" 
                        [minLength]="1">
        </p-autoComplete>
        <ng-container *ngIf="controlForm.errors && controlForm.touched">
            <small *ngIf="controlForm.errors.required" class="p-error">El campo {{datatypeInfo.regist_title | lowercase}} es requerido</small>
        </ng-container>
    </div>
</ng-container>