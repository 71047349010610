<div class="grid grid-nogutter">
    
    <!-- Container for sidebar(s) + page content -->
    <!-- <ng-sidebar-container> -->
        <!-- Sidebar -->
        
        <!-- <ng-sidebar [(opened)]="opened" [mode]="tipoSlideMenu" [closeOnClickOutside]="closeOnClickOutside" [showBackdrop]="showBackdrop" sidebarClass="ng-side-style"> -->
            <!-- <app-side-nav></app-side-nav> -->
        <div #sidenav class="col-fixed" [ngClass]="{'sidenav': !isMobile, 'active' : opened && !isMobile, 'sidenav-mobile': isMobile, 'active-mobile' : opened && isMobile }">
            <app-side-nav-v2></app-side-nav-v2>
        </div>
        <!-- </ng-sidebar> -->
        
        <!-- Page content -->
        <!-- <div ng-sidebar-content style="height: 100%; overflow: hidden; width: calc(100% - 250px);"> -->
        <div class="col">
            <!-- Menu Superior -->
            <app-menu-bar (showMenu)="_toggleSidebar()" ></app-menu-bar>
            
            <!-- <div style="overflow: auto; height: calc(100% - 70px);"> -->
            <div >
                <!-- Breadcums -->
                <app-breadcrumb ></app-breadcrumb>    
                <!-- Paginas a Mostrar -->
                <app-master-page ></app-master-page>
            </div>
        </div> 
    <!-- </ng-sidebar-container> -->
</div>

<p-toast></p-toast>

