import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { AuthService } from 'src/app/login/services/auth.service';
import { HomeService } from '../../services/home.service';

@Component({
   selector: 'app-menu-bar',
   templateUrl: './menu-bar.component.html',
   styleUrls: ['./menu-bar.component.css']
})
export class MenuBarComponent implements OnInit {

   @Output() showMenu = new EventEmitter();

   userMessages: any;
   items: MenuItem[] = [];
   autoDisplay: boolean = true;
   userSystem!: string | null;
   showSystemName: boolean = true;
   styleMensajePane: object = { 'width': '310px', 'min-height': '100px', 'margin-left': '10px' };

   // Simple Bar
   numbers = Array(8).fill(0);
   options = { autoHide: true, scrollbarMinSize: 100, scrollbarMaxSize: 150 };


   constructor(private primengConfig: PrimeNGConfig, public authService: AuthService, private router: Router, private homeService: HomeService) { }

   ngOnInit(): void {
      this.primengConfig.ripple = true;
      this.userSystem = localStorage.getItem('usuarioLogeado');

      if (window.innerWidth <= 768) {
         this.showSystemName = false;
         this.styleMensajePane = { 'width': '95%', 'min-height': '100px', 'margin-left': '10px', 'left': '0' }
      } else {
         this.showSystemName = true;
         this.styleMensajePane = { 'width': '310px', 'min-height': '100px', 'margin-left': '10px' }
      }

      this.items = [
         {
            label: 'Configuracion',
            icon: 'pi pi-fw pi-cog',
         },
         {
            label: 'Perfil',
            icon: 'pi pi-fw pi-user',
            command: () => {
               this.router.navigate(['/home/system'], {queryParams: {nu_conten: 3}})
            }
         },
         {
            separator: true
         },
         {
            label: 'Salir',
            icon: 'pi pi-fw pi-power-off',
            command: () => {
               this.logout();
            }
         }
      ];

      this.homeService.getMessagesApp().subscribe((res) => {
         this.userMessages = res;
         console.log("res de mensajes", res)
      })
   }

   public toogleMenu() {
      this.showMenu.emit(null);
   };

   logout() {
      this.authService.logout();
      this.router.navigateByUrl('/login');
   }

   goMessages() {
      this.router.navigate(['/home/system'], { queryParams: { nu_conten: 4 } });
   }

   @HostListener('window:resize', ['$event'])
   onResize(event: any) {
      if (window.innerWidth <= 768) {
         this.showSystemName = false;
         this.styleMensajePane = { 'width': '85%', 'min-height': '100px', 'margin-left': '10px', 'left': '0' }
      } else {
         this.showSystemName = true;
         this.styleMensajePane = { 'width': '310px', 'min-height': '100px', 'margin-left': '10px' }
      }
   }

}
