import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DatatypeInfo } from '../../interfaces/datatype-info';
import { DTypeBuilder } from '../../interfaces/dtype-builder';
import { DatatypeService } from '../../services/datatype.service';

@Component({
  selector: 'app-datatype12',
  templateUrl: './datatype12.component.html',
  styleUrls: ['./datatype12.component.css'],
})
export class Datatype12Component implements OnInit {

  @Input() PAGE_INFO    : any;          // Informacion de la Pagina
  @Input() REGIST_DATA !: DTypeBuilder; // Informacion de Configuracion del Tipo de Dato

  datatypeInfo !: DatatypeInfo;
    
  constructor(private datatypeSerice: DatatypeService) { }

  ngOnInit(): void {
    this.datatypeInfo = this.datatypeSerice.buildDatatypeValues(this.REGIST_DATA);
    // console.log("DATOS::::::::::::::: ",this.datatypeInfo)
  }
}
