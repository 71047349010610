import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  eventBreadcums = new Subject<string[]>();
  URL: string = environment.urlFrame;

  constructor(private http: HttpClient,) { }

  setBreadcumsItems(breadcums: string[]){
    console.log("llamado a breadcums", breadcums);
    this.eventBreadcums.next(breadcums);
  }

  getMessagesApp(){
    let httpParams    = new HttpParams();
    let codigoUsuario = localStorage.getItem('codigo_usuario');

    if (codigoUsuario)
      httpParams = httpParams.append('usu_cod', codigoUsuario);


    return this.http.get(`${this.URL}/app/messages`, { params: httpParams })
  }
}
