import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject, of, empty } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MessagePageService } from './message-page.service';
import { DialogPageService } from './dialog-page.service';
import { MessageService } from 'primeng/api';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  PARAMS_PAGE: any = {};
  URL: string = environment.urlFrame;
  localURL: string = environment.urlRenderManager;

  public contenedorNombre$    = new Subject<string>(); // Nombre del Contenedor
  public parametros$          = new Subject<object>(); // Parametros 
  public paginaPos$           = new Subject<object>(); // Pagina Post

  constructor (
    private http: HttpClient, 
    private messagePageService: MessagePageService,
    public  dialogPageService: DialogPageService,
    public  messageService: MessageService,
    private _router: Router
  ) { }
   
  // Deprecated
  getConten(numeroConten: number) {
    let httpParams    = new HttpParams();
    let codigoUsuario = localStorage.getItem('codigo_usuario');

    if (codigoUsuario)
      httpParams = httpParams.append('usu_cod', codigoUsuario);

    if (numeroConten)
      httpParams = httpParams.append('id_conten', numeroConten?.toString())

    return this.http.get(`${this.URL}/contenedor`, { params: httpParams })
  }

  getContenConfig(numeroConten: number) {
    let httpParams    = new HttpParams();
    let codigoUsuario = localStorage.getItem('codigo_usuario');

    if (codigoUsuario)
      httpParams = httpParams.append('usu_cod', codigoUsuario);

    return this.http.get(`${this.localURL}/render-manager/v1/container/${numeroConten}`, {params: httpParams});
  }

  getPagina(idPagina: number){
    let httpParams    = new HttpParams();
    let codigoUsuario = localStorage.getItem('codigo_usuario');

    // Crear Query Params
    if (idPagina)
      httpParams = httpParams.append('id_pagina', idPagina?.toString());
    
    if (codigoUsuario)
      httpParams = httpParams.append('usu_cod', codigoUsuario);

    if (this.PARAMS_PAGE)
      httpParams = httpParams.append('params_page',JSON.stringify(this.PARAMS_PAGE));

    return this.http.get(`${this.URL}/pagina`, { params: httpParams })
  }

  execPropag(numeroPagina: string, numeroBoton: string, propagBody: object) {
    let httpParams     = new HttpParams();
    let codigoUsuario  = localStorage.getItem('codigo_usuario');

    // Crear Query Params
    if (numeroPagina)
      httpParams = httpParams.append('id_pagina', numeroPagina);
    
    if (codigoUsuario)
      httpParams = httpParams.append('usu_cod', codigoUsuario);

    if (numeroBoton)
      httpParams = httpParams.append('id_boton',  numeroBoton);
    
    if (this.PARAMS_PAGE)
      httpParams = httpParams.append('params_page', JSON.stringify(this.PARAMS_PAGE));
    
    return this.http.post(`${this.URL}/propag`, propagBody, { params: httpParams });
  };

  execPagPos(idPagina: number, regist_value: any, regist_number: number){
    let httpParams    = new HttpParams();
    let codigoUsuario = localStorage.getItem('codigo_usuario');

    // Crear Query Params
    if (idPagina)
      httpParams = httpParams.append('id_pagina', idPagina?.toString());
    
    if (codigoUsuario)
      httpParams = httpParams.append('usu_cod', codigoUsuario);

    if (this.PARAMS_PAGE)
      httpParams = httpParams.append('params_page',JSON.stringify(this.PARAMS_PAGE));

    if (regist_value)
      httpParams = httpParams.append('regist_value', regist_value);

    if (regist_number)
      httpParams = httpParams.append('regist_number', regist_number.toString());

    return this.http.get(`${this.URL}/propos`, { params: httpParams })
  }

  addParam(parametros: any){
    for (const key in parametros) {
      this.PARAMS_PAGE[key] = parametros[key];
    };
    
    this.parametros$.next(this.PARAMS_PAGE);

    console.log("***** Parametros Totales:", this.PARAMS_PAGE);
  };


  procesPagePost(id_pagina: number, nu_regist: number | any, regist_value: any){
    this.execPagPos(id_pagina, regist_value, nu_regist).subscribe((res: any)=>{
      this.paginaPos$.next({id_pagina: id_pagina, nu_regist: res.regist_to_refresh, data: res.data_page});

      console.log("***** Post Ejecutado:", id_pagina, nu_regist);
    })
  };

  removeParam(keys: string[]){
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      delete this.PARAMS_PAGE[key];  
    }
  };

  addPageRefresh(value: number){
    console.log("***** Agregando Paginas Recargar:", value);
    
    this.PARAMS_PAGE.pages_to_refresh = value;
    this.parametros$.next(this.PARAMS_PAGE);

    this.removePageRefresh();
  };

  removePageRefresh(){
    delete this.PARAMS_PAGE.pages_to_refresh;
  };

  execButton(buttonNumber: number, pageNumber:number, registValues: object): Observable<any> {
    console.log("##################### ejecutando boton: execButton")

    return this.execPropag(pageNumber.toString(), buttonNumber.toString(), registValues).pipe(
      map( (result: any) => {
        // Validar Si Existe Redireccion a Otra Pagina
        if (result.page_redirect)
          this._router.navigate(['/home/system'], {queryParams: {nu_conten: result.page_redirect}});

        // Validar si Existen Alertas a Mostrar
        if (result.msg_alert)
          this.messagePageService.showAlert(result.msg_alert.msg_title, result.msg_alert.msg_body, result.msg_alert.msg_type);
        
        // Validar Si Existe Parametros para remover
        if (result.remove_params){
          let params: string[] = result.remove_params;

          this.removeParam(params);
        };

        // Validar Si Existe Parametros para agregar al contenedor
        if (result.page_params){
          let params: object = result.page_params;

          this.addParam(params);
        };
        
        // Validar Si Existe Paginas a Recargar
        if (result.pages_to_refresh)
          this.addPageRefresh(result.pages_to_refresh);

        // Validar Si existe cerrar Dialog
        if (result.close_dialog && this.dialogPageService.contenedorDialog)
          this.dialogPageService.closeDialog();

        // Si hay Popup Abrirlo
        if (result.open_dialog) 
          this.dialogPageService.buildDialog(result.open_dialog.conten, result.open_dialog.min_vw);

        return result;
      }), 
      catchError( (err, caugth) => {
        this.messagePageService.showAlert(`Mensaje de Error - Pagina ${ err.error.page_id }`, err.error.error_stack, 'error');
        
        return of(err);
      })
    );
  }

  addContenName(argNombreContenedor: string) {
    this.contenedorNombre$.next(argNombreContenedor);
  }

  showAlert(title: any, body: any, type: any) {
    this.messageService.add({severity:type, summary: title, detail: body});
  }

}
