import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogPageService } from '../../services/dialog-page.service';
import { MessagePageService } from '../../services/message-page.service';
import { PageService } from '../../services/page.service';
import { DatatypeService } from 'src/app/datatypes/services/datatype.service';

@Component({
  selector: 'app-form-page',
  templateUrl: './form-page.component.html',
  styleUrls: ['./form-page.component.css'],
  providers: [ConfirmationService, DialogService, MessageService]
})
export class FormPageComponent implements OnInit, OnDestroy {

  @Input() PAGE_INFO  : any;             // Informacion Recibida de MasterPage
  PAGE_CONFIG         : any;             // Configuracion de la Pagina
  registPage         !: string[];        // Registros de la Pagina
  formGroup          !: FormGroup;       // Formulario Reactivo
  showRegDev          : boolean = false; // Mostrar Registros json
  styleWidth         !: string;          // Tamaño maximo de la pagina
  nuPagina            : string = '';     // Numero de Pagina
  styleInputs         : string = '';     // Estilo - Inputs
  datatypeInfo        : any = {};        // Tipo de Datos 
  PAGE_DATA          !: any;             // Data de la Pagina
  PAGE_TITLES        !: any;             // Titulos de la Pagina

  buttonIdLoading     : number | null = null; // Numero de Boton en Carga
  buttonLoading       : boolean = false;      // Indicador de Carga del Boton
  icoLoading          : string = 'pi pi-spin pi-spinner'; // Estilo - Icono de Carga Boton

  /********************************************/
  constructor(
    public datatypeServive      : DatatypeService,
    public pageService          : PageService,
    public dialogPageService    : DialogPageService,
    public formBuilder          : FormBuilder,
    public messageService       : MessageService,
    public dialogService        : DialogService,
    public dialogRef            : DynamicDialogRef,
    public dialogConfig         : DynamicDialogConfig,
    private confirmationService : ConfirmationService,
    private messagePageService  : MessagePageService
  ) { }

  ngOnInit(): void {
    this.getPaginaData();
    
    // Recargar Pagina
    this.pageService.parametros$.subscribe( (res: any) =>{
      if (res.pages_to_refresh && res.pages_to_refresh.includes(this.PAGE_CONFIG.id_page)) {
        this.getPaginaData();
      }
    })
  }

  ngOnDestroy() { }

  showAlert(title: any, body: any, type: any) {
    this.messageService.add({severity:type, summary: title, detail: body});
  }

  public procesButton(btn_id: any, btn_conten: number, btn_confirm: boolean, btn_txt_confirm: string, btn_validators: boolean){

    if (btn_validators == true) {
      this.formGroup.markAllAsTouched();

      if ( this.formGroup.valid == false ) {
        this.showAlert("VALIDACION DE FORMULARIO", 'Uno o mas campos no cumplen con los requisitos para continuar', 'error');
        return;
      }
    };

    if (btn_confirm == true) {
      
      this.confirmationService.confirm({
        message: btn_txt_confirm,
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.ejecutarBoton(btn_id);     
        },
        reject: (type: any) => {
          switch(type) {
              case ConfirmEventType.REJECT:
                //this.messageService.add({severity:'error', summary:'Rejected', detail:'You have rejected'});
              break;
              case ConfirmEventType.CANCEL:
                //this.messageService.add({severity:'warn', summary:'Cancelled', detail:'You have cancelled'});
              break;
          }
        }
      });
    } else{
      this.ejecutarBoton(btn_id);
    }
  }

  public ejecutarBoton( buttonNumber: number ): void {
    // Cambiado el icono del boton a cargando
    this.buttonLoading = true;
    this.buttonIdLoading = buttonNumber;

    // Mensaje Info Consola
    console.log("====> Procesando Progag:", this.PAGE_CONFIG.id_page);

    this.pageService.execButton(buttonNumber, this.PAGE_CONFIG.id_page, this.formGroup.value).subscribe((res:any) => {
      this.buttonLoading   = false; // Resetear Icono Loading del Boton
			this.buttonIdLoading = null;  // Resetear Numero de Boton Cargando
    });
  };

  showRegistDev(){
    this.showRegDev = !this.showRegDev;
  }

  /************** CALL TO SERVICES **************/
  getPaginaData() {
    
    // Consultar al Servicio la Informacion de la Pagina a Renderizar
    this.pageService.getPagina(this.PAGE_INFO.id_page).subscribe( (result: any) => {
      // Mensaje Consola
      console.log("====> Page Loaded:", this.PAGE_INFO.id_page);
      
      // Guardar Datos de la Pagina
      this.PAGE_CONFIG = result;

      // Estilos de Inputs
      this.styleInputs = this.PAGE_CONFIG.input_style == 'filled' ? 'p-input-filled' 
                       : this.PAGE_CONFIG.input_style == 'outlined' ? 'p-input-outlined' 
                       : '';
      // Estableser el ancho maximo de la pagina
      this.styleWidth = !this.PAGE_CONFIG.max_width ? '100%' : this.PAGE_CONFIG.max_width + 'px';

      // Numero de Pagina
      this.nuPagina = this.PAGE_CONFIG.dev_mode ? ' - ' + this.PAGE_CONFIG.id_page : '';

      // Obtener los nombres de los registros (Keys del Objeto)
      this.registPage = Object.keys(this.PAGE_CONFIG.regist_title);

      // Data de la Pagina
      this.PAGE_DATA = this.PAGE_CONFIG.data_page[0];

      // Titulos de la Pagina
      this.PAGE_TITLES = this.PAGE_CONFIG.regist_title;
      
      // Validar si Existen Alertas a Mostrar
      if (result.msg_alert)
        this.messagePageService.showAlert(result.msg_alert.msg_title, result.msg_alert.msg_body, result.msg_alert.msg_type);

      // Crear el Formulario con los Registros de la Pagina
      this.crearFormulario();
    }, err => {
      console.log("error", err.error.valid);
      if (err.error.valid === false) {
        // this.openSnackBar("Mensaje de Error", err.error.error_stack, 'danger');
        console.log("error");
        this.showAlert(`Mensaje de Error - Pagina ${ err.error.page_id }`, err.error.error_stack, 'error');
      };
    });
  };

  public crearFormulario(): void {
    // Objeto para Configurar el FormGroup
    let formObj: any = {};
    
    // Crear cada registro
    this.registPage.forEach( regist => {
      formObj[regist] = new FormControl(this.PAGE_CONFIG.data_page[0][regist]);
    });

    // Crear el FormGroup con Ayuda del FormBuilder
    this.formGroup = this.formBuilder.group(formObj);

    // Crear la Data para el Typo de Dato
    this.registPage.forEach( regist_name => {
      // Informacion para Configurar el Tipo de Dato
      let getType   : string = regist_name + "_type";
      let getEst    : string = regist_name + "_est";
      let getColor  : string = regist_name + "_color";
      let getIco    : string = regist_name + "_ico";
      let getConte  : string = regist_name + "_conten";
      let getDatsel : string = regist_name + "_datsel";
      let getPagRef : string = regist_name + "_pagref";
      let getPlHold : string = regist_name + "_plhold";
      let getDiaCon : string = regist_name + "_dialog";

      let getRequired : string = regist_name + "_required";

      this.datatypeInfo[regist_name] = {
        regist_name       : regist_name,
        regist_value      : this.PAGE_CONFIG.data_page[0][regist_name],
        regist_datatype   : this.PAGE_CONFIG.data_page[0][getType],
        regist_status     : this.PAGE_CONFIG.data_page[0][getEst],
        regist_color      : this.PAGE_CONFIG.data_page[0][getColor],
        regist_icon       : this.PAGE_CONFIG.data_page[0][getIco],
        regist_conten     : this.PAGE_CONFIG.data_page[0][getConte],
        regist_opcselect  : this.PAGE_CONFIG.data_page[0][getDatsel],
        regist_pagref     : this.PAGE_CONFIG.data_page[0][getPagRef],
        regist_plholder   : this.PAGE_CONFIG.data_page[0][getPlHold],
        regist_dialogCon  : this.PAGE_CONFIG.data_page[0][getDiaCon],
        regist_title      : this.PAGE_CONFIG.regist_title[regist_name],
      };
      
      // Crear Validaciones del Formulario Reactivo
      if (this.PAGE_CONFIG.data_page[0][getRequired] == true){
        // this.formGroup.controls[regist_name].setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(20)]);
        this.formGroup.controls[regist_name].setValidators([Validators.required]);
      }
    });
  };
  /**********************************************/  

  log(val: any){
    console.log("valor:", val)
  }
}
