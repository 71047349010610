<!-- <p-breadcrumb [model]="items"  [home]="home"></p-breadcrumb> -->
<!-- <div class="p-grid">
    <div class="p-col-4">
        <p-breadcrumb [model]="items" [home]="home" [separator]="true"></p-breadcrumb>
    </div>
    <div class="p-col-4 p-offset-4 p-shadow-1">
        <div class="box p-pr-6">Home/Requerimientos Cotizacion</div>
    </div>
</div> -->
<!-- 
<div class="p-d-flex p-flex-column p-flex-md-row p-jc-between p-py-3 p-px-4 box">
    <div class="title p-mb-1 p-mb-md-0">{{nombreConten}}</div>
    <div class="p-d-flex p-ai-center breadcum">
        <ng-container *ngIf="breadcums">
            <div>Inicio</div>
            <ng-container *ngFor="let name of breadcums">
                <div class="p-px-2">
                    <i class="pi pi-angle-right" style="font-size: .8rem; color: #ced4da;"></i>
                </div>
                <div>{{name}}</div>
            </ng-container>
        </ng-container>
    </div>
</div> -->

<div class="flex flex-column md:flex-row justify-content-between  py-3 px-4">
    <div class="title mb-1 mb-md-0">{{nombreConten}}</div>
    <div class="flex align-items-center breadcum">
        <ng-container *ngIf="breadcums">
            <div>Inicio</div>
            <ng-container *ngFor="let name of breadcums">
                <div class="px-2">
                    <i class="pi pi-angle-right" style="font-size: .8rem; color: #ced4da;"></i>
                </div>
                <div>{{name}}</div>
            </ng-container>
        </ng-container>
    </div>
</div>