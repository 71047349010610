import { Injectable } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MasterPageComponent } from '../components/master-page/master-page.component';
import { MainSystemComponent } from '../../sub-sistemas/main-system/main-system.component';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogPageService {

  dialog           !: DynamicDialogRef;
  contenedorDialog !: number | null;
  
  constructor(
    public dialogService: DialogService
  ) { }

  buildDialog(nu_conten: number, min_vw: number){

    let minWidth:number = min_vw !== undefined ? min_vw : 5;

    // Seteando Contenedor a Abrir
    this.contenedorDialog = nu_conten;

    // Ancho del Dialog
    if (window.innerWidth < 768) minWidth = 90;

    this.dialog = this.dialogService.open(MasterPageComponent, {
      //header: 'Choose a Product',
      // data:{hola: "dato nuevo", parametro: 123},
      // width: '100%',
      dismissableMask: true,
      styleClass: "maximoancho",
      showHeader: false,
      closable: true,
      //contentStyle: {"min-height": "100px", "min-width": minWidth + "vw", "padding":"0", "overflow": "auto"},
      contentStyle: {"min-height": "100px", "width": minWidth + "vw", "padding":"0"
      //, "overflow-y": "visible"
      },

      // min-width: 100px;
      // max-width: 400px;
      // width: auto;
      //dismissableMask: true,
      //footer: 'Esto es el footer dadas',
      baseZIndex: 10000
    });

    this.dialog.onClose.subscribe( res => {
      this.contenedorDialog = null;
      console.log("====> Cerrando el Dialog -  Borrando Contenedor:", nu_conten);   
    });
  }

  buildMainMenu(nu_conten: number | null, max_px: number){
    let maxWidth:string = max_px !== undefined ? String(max_px)+'px' : '650px';

    // Ancho del Dialog
    if (window.innerWidth < 768) maxWidth = '90vw';

    this.dialog = this.dialogService.open(MainSystemComponent, {
      dismissableMask: true,
      styleClass: "maximoancho",
      showHeader: false,
      closable: true,
      contentStyle: {"min-height": "100px", "min-width": "100px","width": "100%", "max-width": maxWidth, "padding":"0"},
      baseZIndex: 10000
    });

    this.dialog.onClose.subscribe( res => {
      this.contenedorDialog = null;
      console.log("====> Cerrando el Dialog -  Borrando Contenedor:", nu_conten);   
    });
  }

  closeDialog(){
    this.dialog.close();
  }
}
