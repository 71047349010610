import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

type MenuNav = {
  idNavMenu ?: number | null | undefined,
  nameSubNavMenu: string,
  idConten?: number,
  typeNavMenu: string,
  icoNavMenu: string,
  breadcumItems?: String[],
  subNavmenu ?: {
    idSubNavMenu: number,
    idConten?: number,
    nameSubNavMenu: string,
    icoSubNavMenu: string,
    breadcumItems?: String[]
  }[]
};

@Injectable({
  providedIn: 'root'
})
export class RoutesService {
  private urlPages: string = environment.urlFrame;
  public  navMenu$ = new Subject<MenuNav[]>();
  public  navMenu: MenuNav[] = [];

  constructor(private http: HttpClient) { }

  getRoutes(id_usuari: string | undefined | null, codSystem: string | null){

    let httpParams = new HttpParams();
    
    httpParams = httpParams.append('usu_cod', id_usuari || '');
    httpParams = httpParams.append('system_cod', codSystem ?? '');

    return this.http.get(`${this.urlPages}/routes`, {params: httpParams});
  };

  buildMenuGroup(menu_group: any): any {
    let id_temporal_contador: number = 0;
    this.navMenu = [];

    console.log("==================> buildeando rutas desde el servicio",menu_group)
    for (var index in menu_group) {
      
      menu_group[index].route_menus.forEach((menuElement: { displayName: any; iconName: any; route: any; id_conten: any; children: any; breadcum_items: any; }) => {
        id_temporal_contador = id_temporal_contador + 1;
        console.log("==================>id_temporal_contador",id_temporal_contador)
        let itemsMenu = {} as MenuNav;

        itemsMenu.idNavMenu      = id_temporal_contador;
        itemsMenu.idConten       = menuElement.id_conten;
        itemsMenu.nameSubNavMenu = menuElement.displayName;
        itemsMenu.typeNavMenu    =  'UN';
        itemsMenu.icoNavMenu     = menuElement.iconName;
        itemsMenu.breadcumItems  = menuElement.breadcum_items;
        
        if (menuElement.children) {
          let itemsChildMenu : any = [];

          menuElement.children.forEach((menuChildren: { displayName: any; iconName: any; route: any; id_conten: any; breadcum_items: any; }) => {
            itemsChildMenu.push({
              idSubNavMenu   : menuChildren.id_conten,
              idConten       : menuChildren.id_conten,
              nameSubNavMenu : menuChildren.displayName,
              icoSubNavMenu  : menuChildren.iconName,
              breadcumItems  : menuChildren.breadcum_items
            });
          });

          itemsMenu.typeNavMenu   =  'LI';
          itemsMenu.subNavmenu    = itemsChildMenu;
          itemsMenu.breadcumItems = [];
        }

        this.navMenu.push(itemsMenu);
        console.log("this.navMenu", this.navMenu)
      });

      this.navMenu$.next(this.navMenu);
      
      return;
    };
  }
}
