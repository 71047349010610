<!-- <p class="title">SISTEMAS</p> -->

<div class="title">
    <div style="display: flex; align-items: center;font-size: 14px;">
        <i class="fas fa-laptop fa-lg" style="margin-right: 10px;"></i>
        <h5 style="border-left: 1px solid rgb(199, 199, 199); padding-left: 10px">Sistemas</h5>
    </div>
    <div >
        <i class="fas fa-border-all fa-sm"></i>
        <i class="fas fa-list-alt fa-sm"></i>
    </div>
    
</div>
<!-- <div class="container"> -->
<div class="grid-container">
    <!-- <ng-container *ngFor="let item of menuSystem" > -->
        <!-- <div *ngIf="item.idSystem == 1" class="itemMenu shadow-3 card" (click)="getSideMenu(item)">
            <img  class="image__full" src="../../../assets/logo-admin-panel.png">
        </div>
        <div *ngIf="item.idSystem !== 1" class="itemMenu shadow-3 card" (click)="getSideMenu(item)">
            <img class="image__menu" src="../../../assets/admin_logo.png">
            <p>{{item.nameSystem}}</p>
        </div> -->
        <div *ngFor="let item of menuSystem" class="itemMenu" (click)="getSideMenu(item)">
            <img class="image__menu" src="../../../assets/menu_tile-1.png">
            <div class="banner">
                <span style="margin-left: 20px; font-weight: 500;">{{item.nameSystem}}</span>
                <span style="margin-left: 20px; margin-right: 10px; color: #8b8a8a; font-size: 11px;">sistema para la administración</span>
            </div>
            
        </div>
    <!-- </ng-container> -->
</div>

<!-- </div> -->


