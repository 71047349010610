import { HttpClient } from '@angular/common/http';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DatatypeInfo } from '../../interfaces/datatype-info';
import { DTypeBuilder } from '../../interfaces/dtype-builder';
import { DatatypeService } from '../../services/datatype.service';

@Component({
  selector: 'app-datatype04',
  templateUrl: './datatype04.component.html',
  styleUrls: ['./datatype04.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Datatype04Component),
      multi: true
    }
  ]
})
export class Datatype04Component implements OnInit, ControlValueAccessor {

  @Input() PAGE_INFO    : any;          // Informacion de la Pagina
  @Input() REGIST_DATA !: DTypeBuilder; // Informacion de Configuracion del Tipo de Dato
  @Input() controlForm !: FormControl;
  
  datatypeInfo !: DatatypeInfo;
  value        !: string | null;
  isDisabled   !: boolean;
  cleanAtach   !: boolean;

  constructor(private datatypeSerice: DatatypeService, private http: HttpClient) { }

  ngOnInit(): void {
    this.datatypeInfo = this.datatypeSerice.buildDatatypeValues(this.REGIST_DATA);
  }

  onClick(){
    // if (this.datatypeInfo.regist_dialogCon) { // Si hay Popup Abrirlo
    //   this.dialogPageService.buildDialog(this.datatypeInfo.regist_dialogCon);
    // };
  }

  clearAttach(event: any, form : any) {
    this.cleanAtach = false;
    // console.log("myUploader", event, form)
    form.clear();
    this.onInput(null);
    //event.files == files to upload
  }

  onSelectEvent(event: any, form : any) {

    // console.log("onSelectEvent", <File>event.files[0], event.target)

    let selectedFile: File = <File>event.files[0];

    const fd = new FormData();
    fd.append('file', selectedFile, selectedFile.name);

    //this.http.post('http://apifiles.app0.net/api/uploads',fd).subscribe((res: any) =>{
    this.http.post('http://165.227.68.255:8080/api/uploads',fd).subscribe((res: any) =>{
      console.log('Upload File Correct: ', res);
      console.log('BODY: ', fd);

      this.onInput(res.filename);
    });

    this.cleanAtach = true;
    
    //form.clear();
    //event.files == files to upload
  }

  onBasicUpload(event: any) {
    console.log("onBasicUpload",event )
    
    //event.files == files to upload
  }

  /************************* NG VALUE ACCESOR *************************/
  onChange = (_: any) => { };
  onTouch  = () => { }

  writeValue(value: any): void {
    if (value) {
      this.value = value || '';
    } else {
      this.value = '';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onInput(value: string | null) {
    this.value = value;
    this.onTouch();
    this.onChange(this.value);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  /**********************************************************************/

}
