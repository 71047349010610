import { Component, ElementRef, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { HomeService } from '../../services/home.service';
import { RoutesService } from '../../services/routes.service';
import { DialogPageService } from '../../../pages/services/dialog-page.service';
import { PageService } from 'src/app/pages/services/page.service';

type MenuNav = {
  idNavMenu ?: number | null | undefined,
  nameSubNavMenu: string,
  idConten?: number,
  typeNavMenu: string,
  icoNavMenu: string,
  breadcumItems?: String[],
  subNavmenu ?: {
    idSubNavMenu: number,
    idConten?: number,
    nameSubNavMenu: string,
    icoSubNavMenu: string,
    breadcumItems?: String[]
  }[]
};

@Component({
  selector: 'app-side-nav-v2',
  templateUrl: './side-nav-v2.component.html',
  styleUrls: ['./side-nav-v2.component.css']
})
export class SideNavV2Component implements OnInit {

  @ViewChildren('selectSubNavMenu') selectSubNavMenu !: QueryList<ElementRef>;
  idMenuActive?: number;
  idSubmenuActive?: number;
  idMenusOpened: number[] = [];
  userCode !: string ;

  navMenu: MenuNav[] = [];
  constructor(
      private renderer: Renderer2, 
      private routeService: RoutesService, 
      private homeService: HomeService, 
      public dialogPageService: DialogPageService
    ) { }

  ngOnInit(): void {
    this.userCode = localStorage.getItem('codigo_usuario') ?? '';
    // Rutas del Menu Navegacion
    /*this.routeService.getRoutes(this.userCode, null).subscribe((res:any) => {
      // console.log("res>>>>>>", res)
      this.buildMenuGroup(res);
    });*/

    let menuOption: any = localStorage.getItem('menuOptions');
    
    if (menuOption){
      this.navMenu = [];
      this.navMenu = JSON.parse(menuOption);
    };

    this.routeService.navMenu$.subscribe((res) =>{
      localStorage.setItem('menuOptions', JSON.stringify(res));
      
      this.navMenu = [];
      this.idMenusOpened = [];
      this.navMenu = res;
    });

    console.log("INICIANDO MENU LATERAL", this.navMenu)
  }

  buildMenuGroup(menu_group: any) {
    let id_temporal_contador: number = 0;
    //console.log("==================>menu_group",menu_group)
    for (var index in menu_group) {
      
      menu_group[index].route_menus.forEach((menuElement: { displayName: any; iconName: any; route: any; id_conten: any; children: any; breadcum_items: any; }) => {
        id_temporal_contador = id_temporal_contador + 1;
        //console.log("==================>id_temporal_contador",id_temporal_contador)
        let itemsMenu = {} as MenuNav;

        itemsMenu.idNavMenu      = id_temporal_contador;
        itemsMenu.idConten       = menuElement.id_conten;
        itemsMenu.nameSubNavMenu = menuElement.displayName;
        itemsMenu.typeNavMenu    =  'UN';
        itemsMenu.icoNavMenu     = menuElement.iconName;
        itemsMenu.breadcumItems  = menuElement.breadcum_items;
        
        if (menuElement.children) {
          let itemsChildMenu : any = [];

          menuElement.children.forEach((menuChildren: { displayName: any; iconName: any; route: any; id_conten: any; breadcum_items: any; }) => {
            itemsChildMenu.push({
              idSubNavMenu   : menuChildren.id_conten,
              idConten       : menuChildren.id_conten,
              nameSubNavMenu : menuChildren.displayName,
              icoSubNavMenu  : menuChildren.iconName,
              breadcumItems  : menuChildren.breadcum_items
            });
          });

          itemsMenu.typeNavMenu   =  'LI';
          itemsMenu.subNavmenu    = itemsChildMenu;
          itemsMenu.breadcumItems = [];
        }

        this.navMenu.push(itemsMenu);
        console.log("this.navMenu", this.navMenu)
      });

      
      // console.log("1111111111111111111111111111111111111111", 2222, this.navMenu)  
    };

    
    //console.log("333333333333333333333333333333333333333", 2222, itemsMenu)
    
  }

  toogleMenu(id_menu_Active: number) {
    let allSubMenu = this.selectSubNavMenu.toArray(); //Capturamos todos los Submenus
    
    // Recorremos todos los SubMenus
    allSubMenu.forEach((rs: ElementRef) => {
      
      let idMenu    = parseFloat(rs.nativeElement.id);      // ID del Menu
      let heightMax = rs.nativeElement.scrollHeight + 'px'; // Alto Minimo para mostrar el HTML
      
      if (idMenu == id_menu_Active) {
        if (this.idMenusOpened.includes(idMenu)) { // Existe ID Menu Abierto
          this.idMenusOpened = this.idMenusOpened.filter(x => x != idMenu); // Removemos ID Menu
          this.renderer.setStyle(rs.nativeElement, 'height', '0px'); // Ocultamos Menu
        } else {
          this.idMenusOpened.push(idMenu); // Añadimos ID Menu
          this.renderer.setStyle(rs.nativeElement, 'height', heightMax); // Mostramos Menu
        }
      } else {
        this.renderer.setStyle(rs.nativeElement, 'height', '0px'); // Ocultamos Menu
        this.idMenusOpened = this.idMenusOpened.filter(x => x != idMenu); // Removemos ID Menu
      }
    });
    
    console.log("MENUS ABIERTOS:",  this.idMenusOpened)
  }

  menuActive(id_menu_active: number){
    this.idMenuActive = id_menu_active; // Asignamos el ID como Activo
    this.toogleMenu(id_menu_active);
  };

  subMenuActive(id_submenu_active: number){
    this.idSubmenuActive = id_submenu_active; // Asignamos el ID como Activo
    // this.homeService.setBreadcumsItems(event.item.breadcums)
  };

  setBreadcums(breadcums: any){
    this.homeService.setBreadcumsItems(breadcums)
  };

  openMenuSystem(){
    let menuSystem: object[] = [];

    this.routeService.getRoutes(this.userCode, null).subscribe((res: any) => {
      res.forEach((element: any) => {
        menuSystem.push({ idSystem: element.route_code, nameSystem: element.route_group });
      });

      localStorage.setItem('systemMenu', JSON.stringify(menuSystem));
      

      console.log("BUILDEANDO MENU", menuSystem)
      //this.dialogPageService.setMainMenu(menuSystem);
      this.dialogPageService.buildMainMenu(null, 820);
    });
  }

}
