<div class="menu__side" id="menu_side">

    <div class="name__page">
        <i class="fa-solid fa-cubes-stacked"></i>
        <h4>NONAMESOFT</h4>
    </div>
    
    <div class="menu__system" (click)="openMenuSystem()">
        <p>SISTEMAS</p>
        <i class="fas fa-cogs"></i>
    </div>
        
    <ul class="nav__list" *ngFor="let menu_nav of navMenu; let indice = index">
        <ng-container *ngIf="menu_nav.typeNavMenu == 'UN'">
            <li class="list__item single" [ngClass]="{'active': idMenuActive == menu_nav.idNavMenu}">
                <div class="list__button" (click)="menuActive(menu_nav.idNavMenu)">
                    <i class="{{menu_nav.icoNavMenu}} list__img" ></i>
                    <a href="#" routerLink="/home/system" [queryParams]="{nu_conten: menu_nav.idConten}" class="nav__link">{{menu_nav.nameSubNavMenu}}</a>
                </div>
            </li>
        </ng-container>
        <ng-container *ngIf="menu_nav.typeNavMenu == 'LI'">
            <li class="list__item multiple"  [ngClass]="{'active': idMenuActive == menu_nav.idNavMenu}">
                <div class="list__button" (click)="menuActive(menu_nav.idNavMenu)">
                    <i class="{{menu_nav.icoNavMenu}} list__img" ></i>
                    <a href="#" routerLink="/home/system" [queryParams]="{nu_conten: menu_nav.idConten}" class="nav__link">{{menu_nav.nameSubNavMenu}}</a>
                    <i class="fa-solid fa-chevron-right list__arrow" [ngClass]="{'rotate_arrow':  idMenusOpened.indexOf(menu_nav.idNavMenu) !== -1}"></i>
                </div>

                <ul #selectSubNavMenu [id]="menu_nav.idNavMenu" class="list__show">
                    <li *ngFor="let submenu_nav of menu_nav.subNavmenu" (click)="subMenuActive(submenu_nav.idSubNavMenu); setBreadcums(submenu_nav.breadcumItems)" [ngClass]="{'active': submenu_nav.idSubNavMenu == idSubmenuActive}">
                        <a href="#" routerLink="/home/system" [queryParams]="{nu_conten: submenu_nav.idConten}" class="nav__link nav__link--inside">{{submenu_nav.nameSubNavMenu}}</a>
                    </li>
                </ul>
            </li>
        </ng-container>
    </ul>
</div>
